import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';
import {
  IMAGE_SEARCH_URL,
} from '../image_search/Constants';
import {
  MEDIA_QUERIES,
  MEDIA_SIZES,
} from '../../services/constants';
import SegmentClient from '../../services/SegmentClient';
import {
  generateSrcSet,
  generateSrcSizes,
} from '../../services/utils';
import {
  prepareImgixParams,
  arrowLeft,
  arrowRight,
} from '../../services/APIClient';

// Styled Keyframes
export const shakeHorizontal = keyframes`
  0%,
  30% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  3%,
  9%,
  15%,
  21% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  6%,
  12%,
  18% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  24% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  27% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
`;

// Styled Components
export const Container = styled.div.attrs(() => ({
  className: 'grid-x p-r',
}))`
`;

export const Loader = styled.div.attrs(() => ({
  className: 'loading-spinner-l p-a relative-center m-auto ',
}))`
  && {
    &:after {
      border-color: white;
      border-top-color: #9e9e9e;
    }
  }
`;

const Meta = styled.span`
  z-index: 1;
`;
export const MetaDesktop = styled(Meta).attrs(() => ({
  className: 'p-a p-base white hide-for-large',
}))`
`;
export const MetaMobile = styled(Meta).attrs(() => ({
  className: 'float-right show-for-large',
}))`
`;

export const CloseButton = styled.a.attrs(() => ({
  className: 'p-a p-base',
}))`
  top: 0;
  right: 0;
  i {
    z-index: 999;
    color: black;
    font-size: 1rem;
  }

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    i {
      color: white;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, .75);
      font-size: 2rem;
    }
  }
`;

export const ImageWrapper = styled.div.attrs(() => ({
  className: 'text-center full-width full-height',
}))`
  line-height: 99vh;
`;
const Img = styled.img.attrs(() => ({
}))`
  width: auto;
  max-width: 100%;
  max-height: 100%;
`;
const Figure = styled.figure.attrs(() => ({
  className: 'full-width full-height',
}))`
  line-height: inherit;
`;
const BlackBackgroundGradient = styled.div`
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    z-index: -1;
    background: -moz-linear-gradient(${props => (props.top ? 'top' : 'to top')}, rgba(0, 0, 0, .5), transparent);
    background: -webkit-linear-gradient(${props => (props.top ? 'top' : 'to top')}, rgba(0, 0, 0, .5), transparent);
    background: linear-gradient(${props => (props.top ? 'top' : 'to top')}, rgba(0, 0, 0, .5), transparent);
  }
`;
export const Caption = styled(BlackBackgroundGradient).attrs(() => ({
  className: 'p-a full-width p-base white',
}))`
  z-index: 3;
  top: 0;
  bottom: auto;
  opacity: 0;
  transition: opacity .2s;

  > h1 {
    font-size: 1.5625rem;
    max-height: 75px;
  }
  
  &:before {
    height: 125px;
    top: 0;
  }

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    top: auto;
    bottom: 60px;
    opacity: 1;

    > h1 {
      font-size: 1.25rem;
      max-height: 90px;
    }

    &:before {
      content: none;
    }
  }
`;
export const Navigation = styled.div.attrs(() => ({
  className: 'p-a full-width',
}))`
  top: 15%;
  height: 75%;
  line-height: 70vh;
  opacity: .75;
  transition: opacity .2s;

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    top: 60px;
    height: 70%;
    line-height: calc(100vh - 60px - 75px);
    opacity: 1;
  }
`;
const NavigationIcons = styled.a.attrs(() => ({
  className: 'inline-block full-height',
}))`
  padding: 0 1.95312rem;

  img {
    width: 45px;
    height: 75px;
  }

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    padding: 0 0.75rem;

    img {
      width: 25px;
      height: 50px;
    } 
  }
`;
const PreviousIcon = styled(NavigationIcons).attrs(() => ({
  className: 'inline-block text-left',
}))`
  width: 25%;
  padding-right: 0;

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    width: 35%;
    padding-right: 0;
  }
`;
const NextIcon = styled(NavigationIcons).attrs(() => ({
  className: 'inline-block text-right',
}))`
  width: 75%;
  padding-left: 0;

  img {
    animation: ${shakeHorizontal} 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) 5s;
  }

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    width: 65%;
    padding-left: 0;
  }
`;

export const ImageActionsContainer = styled(BlackBackgroundGradient).attrs(() => ({
  className: 'p-a full-width p-base white',
}))`
  z-index: 2;
  bottom: 0;
  opacity: 0;
  transition: opacity .2s;
  text-align: right;

  &:before {
    height: 100px;
    bottom: 0;
  }

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    opacity: 1;
    text-align: center;

    &:before {
      height: 165px;
    }
  }
`;

export const ImageContainer = styled.div.attrs(() => ({
  className: 'cell madium-12 large-9 p-r',
}))`
  height: 100vh;
  transition: background .2s;
  background-color: black;

  &:hover {
    ${Caption}, ${ImageActionsContainer}, ${Navigation} {
      opacity: 1;
    }
  }
`;

export const RightRail = styled.div.attrs(({ isPadded }) => ({
  className: `cell madium-12 large-3 of-auto bg-white ${isPadded ? 'p-base' : ''}`,
}))`
  height: 100vh;

  @media screen and (max-width: ${MEDIA_QUERIES.large}) {
    height: auto;
  }
`;

export const Close = ({ href, onClick }) => (
  <CloseButton href={href} onClick={onClick}>
    <i className="material-icons p-r">close</i>
  </CloseButton>
);
Close.propTypes = { href: PropTypes.string, onClick: PropTypes.func };
Close.defaultProps = { href: null, onClick: () => {} };

export const Previous = ({ href, onClick }) => (<PreviousIcon href={href} onClick={onClick}><img src={arrowLeft} alt="Previous" /></PreviousIcon>);
Previous.propTypes = { href: PropTypes.string, onClick: PropTypes.func };
Previous.defaultProps = { href: null, onClick: () => {} };

export const Next = ({ href, onClick }) => (<NextIcon href={href} onClick={onClick}><img src={arrowRight} alt="Next" /></NextIcon>);
Next.propTypes = { href: PropTypes.string, onClick: PropTypes.func };
Next.defaultProps = { href: null, onClick: () => {} };

export const Image = ({
  url, title, company, credit,
}) => (
  <Figure>
    <Img
      alt={`${title}${company ? ` / ${company}` : ''}${credit ? ` / © ${credit}` : ''}`}
      src={prepareImgixParams(url, MEDIA_SIZES.LARGE)}
      srcSet={generateSrcSet(url, [
        MEDIA_SIZES.LARGE,
        MEDIA_SIZES.MEDIUM,
        MEDIA_SIZES.SMALL,
      ])}
      sizes={generateSrcSizes({
        large: MEDIA_SIZES.LARGE.w,
        medium: MEDIA_SIZES.MEDIUM.w,
        small: MEDIA_SIZES.SMALL.w,
      })}
    />
  </Figure>
);
Image.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  company: PropTypes.string,
  credit: PropTypes.string,
};
Image.defaultProps = {
  title: '',
  company: null,
  credit: null,
};

export const Title = ({
  name,
  url,
  companyNames,
  companySlugs,
  companyUrlRoot,
  linkAllImages,
  ANALYTICS_NAME,
  segment,
}) => (
  <h1 className="fw-bold mb-0 of-auto">
    {/* Project Name */}
    <a
      href={url}
      className="white hover-blue"
      onClick={() => segment.track(ANALYTICS_NAME.MODAL_PROJECT_CLICKED, {}, {
        Intercom: false,
      })}
    >
      {name}
    </a>
    {name && Boolean(companyNames.length) && <span> / </span>}
    {/* Company */}
    {companyNames.map((companyName, i) => (
      <Fragment key={companyName}>
        {/* Company Name */}
        <a
          href={companySlugs[i] && companyUrlRoot ? `/${companyUrlRoot}/${companySlugs[i]}/` : null}
          className="white hover-blue"
          onClick={() => segment.track(ANALYTICS_NAME.MODAL_FIRM_CLICKED,
            { name: companyName, slug: companySlugs[i] }, { Intercom: false })}
        >
          {companyName}
        </a>
        {/* View All Images */}
        {linkAllImages && (
        <Tooltip placement="bottom" tooltip="View All Firm Images">
          <a
            className="white hover-blue"
            href={`${IMAGE_SEARCH_URL}firm=${encodeURIComponent(companyName)}`}
            onClick={() => {
              segment.track(ANALYTICS_NAME.MODAL_VIEW_ALL_FIRM_IMAGES,
                { name: companyName, slug: companySlugs[i] }, { Intercom: false });
            }}
          >
            {' '}
            <i className="material-icons">burst_mode</i>
          </a>
        </Tooltip>
        )}
        <span>{i < companyNames.length - 1 ? ', ' : ''}</span>
      </Fragment>
    ))}
  </h1>
);
Title.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  companyNames: PropTypes.arrayOf(PropTypes.string),
  companySlugs: PropTypes.arrayOf(PropTypes.string),
  companyUrlRoot: PropTypes.string,
  linkAllImages: PropTypes.bool,
  ANALYTICS_NAME: PropTypes.shape(),
  segment: PropTypes.instanceOf(SegmentClient),
};
Title.defaultProps = {
  name: '',
  url: '',
  companyNames: [],
  companySlugs: [],
  companyUrlRoot: null,
  linkAllImages: false,
  ANALYTICS_NAME: {},
  segment: new SegmentClient(),
};
