import 'url-polyfill';
import 'whatwg-fetch';
import { oneLine } from 'common-tags';

import {
  getCSRFToken,
} from './clientsUtils';

// Define constants
const CSRF_TOKEN = getCSRFToken();
const API_ENDPOINT = '/api/v3.0/';
const headers = {
  Accept: 'application/json',
  'X-CSRFToken': CSRF_TOKEN,
};
const SUGGESTERS = [
  'brands',
  'firms',
  'request_names',
  'projects',
  'suggestion_terms',
];
const MODELS = [
  'brands',
  'firms',
  'brands_global',
  'product_requests',
  'product_responses',
];

/*
 * Search clients return a Promise to resolve a query against Architizer's SOLR passthroughs
 */
export const SuggesterClient = (suggester, query, count = 10) => {
  if (SUGGESTERS.indexOf(suggester) < 0) {
    throw new Error(`${suggester} is not a valid suggester! Use one of ${SUGGESTERS}`);
  }
  const endpoint = new URL(`${window.location.origin}${API_ENDPOINT}search/${suggester}/suggest/v1/brand_count/`);
  const method = 'GET';
  const credentials = 'same-origin';
  endpoint.searchParams.append('q', query);
  endpoint.searchParams.append('suggest.count', count);
  const request = new Request(endpoint, { headers, method, credentials });

  return fetch(request);
};

// TODO: Refactor this as a class that can be constructed with the params below
export const SearchClient = (
  model,
  query,
  facet = new Map(),
  fq = new Set(),
  fl = [],
  bq = '',
  start = 0,
  rows = 10,
  qf = '',
) => {
  if (MODELS.indexOf(model) < 0) {
    throw new Error(`${model} is not a valid model! Use one of ${MODELS}`);
  }
  const endpoint = new URL(`${window.location.origin}${API_ENDPOINT}search/${model}/select/`);

  const method = 'GET';
  const credentials = 'same-origin';
  endpoint.searchParams.append('q', query);
  endpoint.searchParams.append('start', start);
  endpoint.searchParams.append('rows', rows);
  endpoint.searchParams.append('qf', qf);
  fq.forEach(name => endpoint.searchParams.append('fq', name));
  if (facet.size > 0) {
    const facetFormatted = oneLine`
      {
        ${facet.get('label')}:
          {
            type: ${facet.get('type')},
            field: ${facet.get('terms')},
            limit: ${facet.get('limit')},
            sort: ${facet.get('sort')},
            mincount: ${facet.get('mincount')}
          }
        }
      }`;
    endpoint.searchParams.append('json.facet', facetFormatted);
  }
  if (bq) {
    endpoint.searchParams.append('defType', 'edismax');
    endpoint.searchParams.append('bq', bq);
  }
  if (fl.length > 0) {
    endpoint.searchParams.append('fl', fl.join(','));
  }
  const request = new Request(endpoint, { headers, method, credentials });

  return fetch(request);
};
