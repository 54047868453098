/* eslint-disable max-len, class-methods-use-this, no-prototype-builtins, no-unused-vars, no-unreachable, no-alert */
import React, { Fragment } from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationSystem from 'react-notification-system';
import SegmentClient from '../../services/SegmentClient';

import {
  notificationStyles,
  successNotificationSettings,
  errorNotificationSettings,
} from '../../services/utils';

import {
  BrandMessageResource,
} from '../../services/RESTClient';
import { iterUnpack } from '../../services/APIClient';

const CTA = styled.button`
  width: auto;
`;
const Radio = styled.input`
  vertical-align: top;
`;
const RadioLabel = styled.label`
  && {
    display: inline-block;
    width: 85%;
    line-height: 1rem;
  }
`;

const segment = new SegmentClient();

class BrandMessage extends React.Component {
  constructor(props) {
    super(props);
    const data = iterUnpack(JSON.parse(window.props));
    const brandMessageTopics = data.brandMessageTopics;
    const brandDetail = data.allBrands.length ? data.allBrands[0] : {};

    this.notificationSystem = null;
    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.state = {
      ...this.defaultState(),
      brandName: brandDetail.name,
      brandSlug: brandDetail.slug,
      brandId: brandDetail.djangoId,
      productId: this.props.productId,
      productName: this.props.productName,
      brandMessageTopics,
    };
  }

  componentDidMount() {
  }

  async onFormSubmit(values, props, brandId, productId) {
    if (!values.topic || !values.description || !brandId) {
      props.setSubmitting(false);
      return;
    }
    const topic = values.topic.value;
    const description = values.description;

    BrandMessageResource.createMessage(
      brandId,
      productId,
      topic,
      description,
    ).then((data) => {
      if (!data.success) {
        throw data;
      }
      props.setSubmitting(false);
      this.setState({ isSent: true });
    }).catch((error) => {
      let message = 'Error while submitting the message';
      if (error.metadata.status) {
        message = 'You have reached your message limit for now. Please try again later.';
      } else {
        Raven.captureException(error);
      }
      props.setSubmitting(false);
      this.notificationSystem.addNotification({
        ...errorNotificationSettings,
        message,
      });
    });
  }

  defaultState = () => ({
  });

  renderConfirmation = () => {
    const {
      brandName,
    } = this.state;
    return (
      <>
        <p><strong>Your message has been sent to our representative at {brandName}.</strong></p>
        <p>
          <strong>
            Our team will update you within 24-48 hours. <br />
            We appreciate your patience!
          </strong>
        </p>
      </>
    );
  }

  renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  }) => {
    const {
      brandMessageTopics,
    } = this.state;
    return (
      <form className="" onSubmit={handleSubmit}>
        {/* Form typeahead */}
        <h4 className="fs-base mb-xs mt-xxs"><strong>Contact this brand about</strong></h4>
        <Select
          name="topic-selector"
          className="large border-gray-200 br-s cursor-after-placeholder"
          placeholder={(
            <span>
              <i className="material-icons mr-xxs">search</i>
              Select a topic
            </span>
          )}
          onChange={option => setFieldValue('topic', option, true)}
          value={values.topic}
          options={brandMessageTopics}
          disabled={false}
          tabSelectsValue={false}
          deleteRemoves={false}
          backspaceRemoves={false}
          resetValue="reset value"
          ignoreAccents={false}
        />

        <h4 className="fs-base mb-xs mt-s"><strong>Description</strong></h4>
        <textarea
          rows="5"
          style={{
            borderColor: '#ccc',
            resize: 'none',
          }}
          value={values.description}
          id="description"
          name="description"
          placeholder="Please provide details about your project or request...."
          onChange={event => setFieldValue('description', event.target.value.trimStart())}
        />
        <div className="text-center mt-base">
          <CTA
            type="submit"
            className="button primary ml-xs"
            disabled={!values.description || !values.topic || isSubmitting}
          >
            Send
          </CTA>
        </div>
      </form>
    );
  }

  render() {
    const {
      brandMessageTopics,
      brandName,
      brandSlug,
      brandId,
      productId,
      productName,
      isSent,
    } = this.state;

    return (
      <div className="flex-container flex-dir-column pb-base">
        <Formik
          initialValues={{}}
          onSubmit={(
            values, props,
          ) => this.onFormSubmit(values, props, brandId, productId)}
        >
          {(formikPayload) => (
            <div className="">
              {/* Selected images information and photographers list */}
              <div className="fs-base">
                <Fragment key="testkey">
                  <div className="row align-center">
                    <div className={productName && productId ? 'small-6' : 'small-12'}>
                      <div><strong>To:</strong></div>
                      <p className="mb-0">
                        {brandName}
                      </p>
                    </div>
                    {productName && productId ? (
                      <div className="small-6">
                        <div><strong>Product:</strong></div>
                        <p className="mb-0">
                          {productName}
                        </p>
                      </div>
                    ) : ''}
                  </div>
                </Fragment>
              </div>
              <hr className="mt-base mb-base" />
              {!isSent ? this.renderForm(formikPayload) : this.renderConfirmation()}
              {/* Notification */}
              <NotificationSystem
                ref={(ref) => {
                  this.notificationSystem = ref;
                }}
                noAnimation
                style={notificationStyles}
              />
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

BrandMessage.propTypes = {
  productId: PropTypes.number,
  productName: PropTypes.string,
};

BrandMessage.defaultProps = {
  productId: null,
  productName: null,
  isSent: false,
};

export default BrandMessage;
