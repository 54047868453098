import React from 'react';
import PropTypes from 'prop-types';
import sample from 'lodash/sample';

import {
  ANALYTICS_NAME,
  BASE_URL,
  MEDIA_BASE_URL,
} from './Constants';
import AdHolder from '../AdHolder';
import {
  Container,
  Loader,
  MetaDesktop,
  MetaMobile,
  Close,
  ImageWrapper,
  Caption,
  Navigation,
  ImageActionsContainer,
  ImageContainer,
  RightRail,
  Previous,
  Next,
  Image,
  Title,
} from '../universal_modal/SharedComponents';
import APIClient from '../../services/APIClient';
import ImageActions from '../universal_modal/ImageActions';
import FailedPlaceholder from '../universal_modal/FailedPlaceholder';
import DetailsMediaCredit from '../universal_modal/DetailsMediaCredit';
import UniversalModalDetails from '../universal_modal/UniversalModalDetails';
import BrandsLeaderboard from '../universal_modal/BrandsLeaderboard';
import RequireRegisterPopup, { PERMISSION_TYPES } from '../RequireRegisterPopup';
import {
  NO_PROJECT_TYPE,
} from '../universal_modal/Constants';
import {
  arrayToReadableString,
} from '../../services/utils';
import {
  AD_UNITS as GAM_AD_UNITS,
  AD_CLIENTS,
} from '../../services/constants';

export default class CollectionMediaDetails extends UniversalModalDetails {
  constructor(props) {
    super(props);

    this.ANALYTICS_NAME = ANALYTICS_NAME;
    this.state = {
      ...this.state,
      ...this.defaultState(),
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  updateMediaCredit = ({ firm: { slug: newFirmSlug, name: newFirmName } }) => {
    if (newFirmSlug && newFirmName) {
      const { items, activeItemIndex } = this.state;
      const itemsClone = [...items];
      itemsClone[activeItemIndex] = {
        ...(itemsClone[activeItemIndex] || {}),
        creditName: newFirmName,
        creditSlug: newFirmSlug,
      };
      this.setState({ items: itemsClone });
    }
  };

  getActiveItemDetails = () => {
    const { items, activeItemIndex } = this.state;

    if (!items || !items.length || !items[activeItemIndex] || items[activeItemIndex].isLoaded) {
      return;
    }
    this.setState({ isLoading: true });

    const query = `{
      allImages(first: 1, mediaItemAttributionId: ${items[activeItemIndex].id}) {
        images: edges {
          node {
            globalId
            url
            mediaAttributionId
            projectUrl
            levelTwoProjectTypes
            firmSlugs
            firmNames
            firmIds
            projectName
            creditName
            creditSlug
            mediaId
          }
        }
      }
    }`;
    APIClient(query, true).then((data) => {
      if (!this.isUnmounted) {
        const updatedItems = [...items];
        const { images } = data.allImages;

        if (!images.length) {
          this.props.pushHistory(`/${BASE_URL}/${this.props.collectionSlug}/?image_notfound=1`);
          if (this.props.notfoundCallback) {
            this.props.notfoundCallback();
          }
          this.close();
        }

        updatedItems[activeItemIndex] = {
          ...items[activeItemIndex],
          ...images.length ? images[0].node : {},
          isLoaded: Boolean(images.length),
          // To be replaced with more reliable logic instead of random
          brandsProjectType: sample(
            images.length ? images[0].node.levelTwoProjectTypes : null,
          ) || NO_PROJECT_TYPE,
        };

        this.setState({ items: updatedItems, isLoading: false });
      }
    }).catch(() => {});
  }

  getDocumentTitle = () => document.title;

  pushHistory = () => {
    const { items, activeItemIndex } = this.state;
    this.props.pushHistory(`/${BASE_URL}/${this.props.collectionSlug}/${MEDIA_BASE_URL}/${items[activeItemIndex].id}/`);
  };

  // Override generic open event
  triggerGenericOpenEvent = () => {}

  // RelatedProducts component will be responsible for triggering open event to load it's data along
  triggerOpenEvent = (props = {}) => {
    this.segment.track(this.ANALYTICS_NAME.MODAL_OPENED, props);
  }

  defaultState = () => ({
  });

  render() {
    const { isLoading, items, activeItemIndex } = this.state;

    const isValidItem = items && items.length && typeof activeItemIndex === 'number' && items[activeItemIndex];
    const item = isValidItem ? items[activeItemIndex] : {};
    const meta = isValidItem ? `${activeItemIndex + 1} / ${items.length}` : null;

    const {
      id = null,
      url = null,
      projectName = null,
      projectUrl = null,
      creditName = null,
      firmNames = [],
      firmSlugs = [],
      firmIds = [],
      isLoaded = false,
    } = item;
    const firmsReadableString = arrayToReadableString(firmNames, { serialComma: true });

    return (
      <Container>
        {/* Meta */}
        <MetaDesktop>{meta}</MetaDesktop>
        {/* Close Button */}
        <Close href={null} onClick={() => this.props.close(true)} />

        {/* Left Area - Image */}
        <ImageContainer>
          {/* Image */}
          <ImageWrapper>
            {isLoading && <Loader />}
            {!isLoading && !isLoaded && (
            <FailedPlaceholder
              back={{ onClick: () => this.props.close(true) }}
            />
            )}
            {isLoaded && (
            <Image
              url={url}
              title={projectName}
              company={firmsReadableString}
              credit={creditName}
            />
            )}
          </ImageWrapper>
          {/* Caption */}
          <Caption top>
            <MetaMobile>{meta}</MetaMobile>
            <Title
              name={projectName}
              url={projectUrl}
              companyNames={firmNames}
              companySlugs={firmSlugs}
              companyUrlRoot="firms"
              linkAllImages
              ANALYTICS_NAME={ANALYTICS_NAME}
            />
            <DetailsMediaCredit
              media={item}
              isEditable={Boolean(this.userResource.canEditProject(firmIds))}
              callback={this.updateMediaCredit}
            />
          </Caption>
          {/* Navigation */}
          <Navigation>
            <Previous onClick={() => this.previous({ event: ANALYTICS_NAME.ON_IMAGE_PREVIOUS })} />
            <Next onClick={() => this.next({ event: ANALYTICS_NAME.ON_IMAGE_NEXT })} />
          </Navigation>
          {/* Image Actions */}
          <ImageActionsContainer>
            <ImageActions
              item={item}
              ANALYTICS_NAME={ANALYTICS_NAME}
              segment={this.segment}
              openRequireRegister={this.openRequireRegister}
            />
          </ImageActionsContainer>
        </ImageContainer>

        {/* Right Area - Rail */}
        <RightRail>
          {/* Project Type Brands */}
          <BrandsLeaderboard
            id={id}
            projectTypeText={item.brandsProjectType}
            parentIsLoading={isLoading}
            mediaItemUrl={url}
            segment={this.segment}
            triggerOpenEvent={this.triggerOpenEvent}
            refreshAd={this.refreshAd}
          />
          {/* Ad-Unit */}
          <AdHolder
            client={AD_CLIENTS.GAM}
            adUnit={GAM_AD_UNITS.IMAGE_MODAL_COLLECTION}
            ref={this.adHolderRef}
            center
          />
        </RightRail>
        {/* Content Gates */}
        {this.state.openRequireRegister && (
        <RequireRegisterPopup
          submitProfessional
          joinCompany={this.state.permissionType === PERMISSION_TYPES.FIND_SIMILAR}
          permissionDestinationTitle={this.state.destinationTitle}
          permissionType={this.state.permissionType}
          nextParam={this.state.requireRegisterNext}
          onClose={this.closeRequireRegister}
        />
        )}
      </Container>
    );
  }
}

CollectionMediaDetails.propTypes = {
  isUniversalModal: PropTypes.bool,
  collectionSlug: PropTypes.string,
  notfoundCallback: PropTypes.func,
};

CollectionMediaDetails.defaultProps = {
  isUniversalModal: false,
  notfoundCallback: null,
  collectionSlug: '',
};
