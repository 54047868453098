import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link,
  Element,
  scroller,
  Events,
} from 'react-scroll';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import random from 'lodash/random';

import APIClient, {
  prepareImgixParams,
  defaultLogoImageUrl,
} from '../../services/APIClient';
import SegmentClient from '../../services/SegmentClient';
import {
  ANALYTICS_NAME,
  BRAND_EXPOSURE_SECTIONS,
  BRAND_EXPOSURE_ACTIONS,
  PROJECT_TYPE_ROOT_SLUG,
  SECTIONS,
  BRANDS_COUNT,
  RESULTS_CONTAINER_ID,
  PSEUDOTIERS_NAV_ID,
  NO_PROJECT_TYPE,
} from './Constants';

const defaultProjectType = {
  text: null,
  pseudoTiers: [],
  activePseudoTierIndex: 0,
  isLoading: false,
};

const Ul = styled.ul.attrs(() => ({
  className: 'm-0',
}))`
  && {
    list-style: none;
  }
`;
const Header = styled.div.attrs(() => ({
  className: 'cell bg-extra-light-gray pl-s pr-s pt-xs pb-xxxs border-gunmetal-400',
}))`
  border-bottom: 1px solid;
`;
const Title = styled.h5.attrs(() => ({
  className: 'fw-bold black ellipsis',
}))`
  && {
    text-transform: none;
    letter-spacing: normal;
  }
`;
const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px;
  border-color: #ced9df transparent transparent transparent;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(100% + 6px);

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px;
    border-color: #f7f9fa transparent transparent transparent;
    position: absolute;
    top: -10px;
    left: -8px;
  }
`;

const PseudoTierNavArrow = styled.a.attrs(() => ({
  className: 'display-inline-block gunmetal-600 hover-gunmetal-900 unselectable',
}))`
  width: 25px;
  height: 25px;
`;
const PseudoTiersUl = styled(Ul).attrs(() => ({
  className: 'p-r m-0 of-y-auto display-inline-block',
  id: PSEUDOTIERS_NAV_ID,
}))`
  white-space: nowrap;
  width: calc(100% - 50px);
  height: 25px;
  vertical-align: text-top;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const PseudoTiersLi = styled.li.attrs(() => ({
  className: 'display-inline-block unselectable',
}))`
  vertical-align: top;
`;
const PseudoTierLink = styled(Link).attrs(() => ({
  className: 'display-inline-block p-xxxs pl-xs pr-xs mr-xxxs gray-500 border-gunmetal-300 br-max hover-bg-extra-light-gray',
}))`
  && {
    font-size: .75rem;
    line-height: .75rem;
    vertical-align: middle;
    border: 1px solid;

    &:hover {
      border: 1px solid;
    }
  }
`;

const PseudoTiersResults = styled(Element).attrs(() => ({
  className: 'p-r of-y-auto',
}))`
  && {
    height: calc(100vh - ${window.isUsingAdb ? '120' : '390'}px);
    padding-bottom: 165px;
  }
`;

const Placeholder = styled.div.attrs(() => ({
  className: 'bg-gunmetal-300 br-max mb-xxxs',
}))`
  width: ${props => (props.width ? props.width : '75%')};
  height: 15px;
  && {
    margin: ${props => (props.center ? 'auto' : 'unset')};
  }
`;

const BrandRow = styled.a.attrs(({ isPaid }) => ({
  className: `cell grid-x ${isPaid ? 'black' : 'gray-400'} hover-blue hover-bg-extra-light-gray cursor-pointer`,
}))`
  &:hover {
    .name {
      text-decoration: underline;
    }
  }
`;

const VerifiedIcon = styled.i.attrs(() => ({
  className: 'verified p-a',
  title: 'Verified Brand',
}))`
  && {
    font-style: normal;
    top: -2px;
    left: 0;
    right: 0;
  }
`;
const Verified = ({ isPaid }) => (
  isPaid ? <VerifiedIcon title="Premium Brand">+</VerifiedIcon> : <></>
);
Verified.propTypes = { isPaid: PropTypes.bool };
Verified.defaultProps = { isPaid: false };

const EmptyImg = styled.div.attrs(() => ({
  className: 'mr-xs display-inline-block',
}))`
  width: 12px;
  height: 12px;
`;

const PseudoTierBrands = ({
  pseudoTier,
  index,
  projectType,
  segment,
}) => (
  <Element name={pseudoTier.slug}>
    <div className={`grid-x text-center ${index === 0 ? 'pt-xs' : 'pt-base'}`}>
      <div
        className="cell small-7 fs-s fw-bold text-left ellipsis pl-s"
        title={pseudoTier.name}
      >
        <u>{pseudoTier.name}</u>
      </div>
      <div className="cell small-2 fs-xs gray-500 ellipsis">
        <span style={{ verticalAlign: 'sub' }}>PROJECTS</span>
      </div>
      <div className="cell small-3 fs-xs gray-500 ellipsis">
        <span style={{ verticalAlign: 'sub' }}>PRODUCTS</span>
      </div>
      {pseudoTier.brands && pseudoTier.brands.length
        ? pseudoTier.brands.map((brand, i) => (
          <BrandRow
            key={`${pseudoTier.slug}-${brand.slug}`}
            href={`/brands/${brand.slug}/`}
            onClick={() => segment.track(ANALYTICS_NAME.BRANDS_EXPOSURE, {
              section: BRAND_EXPOSURE_SECTIONS.BRANDS_LEADERBOARD_BRAND_CLICK,
              action: BRAND_EXPOSURE_ACTIONS.BRAND_CLICK,
              exposed_brands: `${brand.djangoId}`,
              exposed_products: '',
              exposed_pseudotiers: pseudoTier.slug,
              exposed_project_type: projectType,
            })}
            isPaid={brand.isPaid}
            index={i}
          >
            <div
              className="cell small-7 name fs-s text-left ellipsis pl-s"
              title={brand.name}
            >
              {brand.isPaid
                ? (
                  <img
                    className="mr-xs"
                    src={brand.logoUrl}
                    width="12"
                    height="12"
                    alt={`${brand.name} Logo`}
                  />
                )
                : <EmptyImg />}
              {brand.name}
            </div>
            <div
              className="cell small-2 fs-s ellipsis"
              title="Connections with Firms' Projects"
            >
              {brand.connectedProjectsCount || '-'}
            </div>
            <div className="cell small-3 fs-s ellipsis">{brand.productsCount || '-'}</div>
          </BrandRow>
        ))
        : range(5).map(i => (
          <Fragment key={`${pseudoTier.slug}-${i}`}>
            <div className="cell small-7"><Placeholder width="90%" center /></div>
            <div className="cell small-2"><Placeholder width="25px" center /></div>
            <div className="cell small-3"><Placeholder width="25px" center /></div>
          </Fragment>
        ))}
    </div>
  </Element>
);
PseudoTierBrands.propTypes = {
  pseudoTier: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    brands: PropTypes.arrayOf(PropTypes.shape({
      isPaid: PropTypes.bool,
      name: PropTypes.string,
      slug: PropTypes.string,
      connectedProjects: PropTypes.int,
      products: PropTypes.int,
    })),
  }).isRequired,
  index: PropTypes.number,
  projectType: PropTypes.string,
  segment: PropTypes.instanceOf(SegmentClient),
};
PseudoTierBrands.defaultProps = {
  index: 0,
  projectType: null,
  segment: new SegmentClient(),
};

export default class BrandsLeaderboard extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.projectTypeText !== state.activeProjectTypeText) {
      // Uncomment to enable caching results
      // const keys = Object.keys(state.projectTypes || {});
      // const projectTypes = pick(state.projectTypes, keys.slice(keys.length - 2));
      return {
        projectTypes: {},
        activeProjectTypeText: props.projectTypeText,
        activeSection: SECTIONS.BRANDS,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.visibleBlocks = 5;
    // Caches pseudotiers that got tracked, cleared on item change.
    this.trackedPseudotiers = {};
    // Set to true when a new item is passed through props.
    this.isNewItem = false;
  }

  componentDidMount() {
    Events.scrollEvent.register('end', this.scrollPseudoTiersNavToActive);
  }

  componentDidUpdate(prevProps) {
    // New item is passed through this.props
    if (prevProps.id !== this.props.id) {
      this.isNewItem = true;
      this.trackedPseudotiers = {};
    }

    if (prevProps.projectTypeText !== this.props.projectTypeText
      || prevProps.id !== this.props.id) {
      // No project type is supplied, just send analytics for opening modal
      if (this.props.projectTypeText === NO_PROJECT_TYPE) {
        this.sendAnalytics();
      } else {
        this.getPseudoTiersForProjectType();
      }
    }
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('end');
  }

  getPseudoTiersForProjectType = () => {
    const { activeProjectTypeText } = this.state;

    if (!activeProjectTypeText) {
      return;
    }

    const activeProjectType = this.getActiveProjectType();

    // Pseudotiers are already fetched for Project Type -> just track them for new items.
    if (activeProjectType.pseudoTiers.length) {
      this.trackPreviouslyFetchedBrands(activeProjectType);
      return;
    }

    this.setState({ isLoading: true });

    const body = JSON.stringify({
      query: `query($projectTypeText: String!, $rootSlug: String!) {
        projectType: allTags(text: $projectTypeText, parent_Parent_Slug: $rootSlug) {
          edges{
            node{
              text
              slug
              placementPseudoTiers {
                name
                slug
                count
              }
            }
          }
        }
      }`,
      variables: {
        projectTypeText: activeProjectTypeText,
        rootSlug: PROJECT_TYPE_ROOT_SLUG,
      },
    });

    APIClient('', true, body).then((data) => {
      try {
        if ('errors' in data) {
          throw new Error(data.errors);
        }
        const { projectTypes } = this.state;
        const { text, placementPseudoTiers, slug } = data.projectType[0];
        projectTypes[activeProjectTypeText] = {
          text,
          slug,
          activePseudoTierIndex: 0,
          pseudoTiers: placementPseudoTiers,
        };
        this.setState({ isLoading: false, projectTypes }, () => {
          this.calcVisibleBlocksCount();
          this.movePseudoTier(random(placementPseudoTiers.length - 1));
          // No pseudotiers for project type, send analytics for opening modal
          if (!placementPseudoTiers.length) {
            this.sendAnalytics();
          }
        });
      } catch (err) {
        // Unable to retrieve Pseudotiers for Project Type, just send MODAL_OPEN event.
        this.sendAnalytics();
        this.setState({ isLoading: false });
        Raven.captureException(err);
      }
    });
  };

  /**
   * Returns an array of projectType's PseudoTiers slugs that needs to be fetched from the server.
   * Logic:
   * - Divides projectType's PseudoTiers into (this.visibleBlocks) lenghted blocks.
   * - Determines which blocks the user can see based on the projectType activePseudoTierIndex.
   * - Returns that block's PseudoTiers slug.
   *
   * @param {<Object>} projectType: the projectType object which should be currently active.
   */
  getPseudoTiersToFetch = (projectType) => {
    const projectTypeRef = projectType;
    const {
      activePseudoTierIndex = 0,
      pseudoTiers = [],
    } = projectTypeRef || {};
    const pseudoTiersToFetch = [];
    const brandsToBeTracked = [];

    let startIndex;
    let endIndex;
    // Get all pseudotiers when all of them are visible
    if (pseudoTiers.length <= this.visibleBlocks) {
      startIndex = 0;
      endIndex = pseudoTiers.length;
    // Special case: when getting pseudotiers for the first time (this.isNewItem is true),
    // and because we are scrolling to a random pseudotier, we need to get them based on
    // index starting from current => current + visibleBlocks.
    } else if (this.isNewItem) {
      startIndex = activePseudoTierIndex;
      endIndex = activePseudoTierIndex + this.visibleBlocks;
    // Other cases will be determined based on blocks rather than index.
    } else if (!this.isNewItem) {
      const minVisibleIndex = activePseudoTierIndex - this.visibleBlocks;
      const minVisibleBlock = Math.floor(minVisibleIndex / this.visibleBlocks);
      const maxVisibleIndex = activePseudoTierIndex + this.visibleBlocks;
      const maxVisibleBlock = Math.ceil(maxVisibleIndex / this.visibleBlocks);
      startIndex = minVisibleBlock * this.visibleBlocks;
      endIndex = maxVisibleBlock * this.visibleBlocks;
    }
    // Make sure indexes are within boundaries
    startIndex = startIndex < 0 ? 0 : startIndex;
    endIndex = endIndex > pseudoTiers.length ? pseudoTiers.length : endIndex;

    range(startIndex, endIndex).forEach((i) => {
      const {
        slug = null, brands = [], isLoading, isLoaded,
      } = pseudoTiers[i] || {};

      if (slug && brands.length === 0 && !isLoading && !isLoaded) {
        pseudoTiers[i].isLoading = true;
        pseudoTiersToFetch.push(slug);
      } else if (isLoaded && !this.trackedPseudotiers[slug]) {
        brandsToBeTracked.push(...brands.map(b => ({
          brand: b.djangoId,
          pseudotier: slug,
        })));
      }
    });

    if (pseudoTiersToFetch.length) {
      this.updateProjectType(projectTypeRef);
    }

    return {
      pseudoTiersToFetch,
      brandsToBeTracked,
    };
  }

  getPseudoTiersBrands = () => {
    const activeProjectType = this.getActiveProjectType();

    const {
      pseudoTiersToFetch = [],
      brandsToBeTracked = [],
    } = this.getPseudoTiersToFetch(activeProjectType);

    // Brands are already fetched for Pseudotiers of Project Type, track them.
    if (brandsToBeTracked.length) {
      this.sendAnalytics({ brands: brandsToBeTracked });
    }
    if (!pseudoTiersToFetch.length) {
      return;
    }

    const body = JSON.stringify({
      query: `query(
        $projectTypeText: String!,
        $rootSlug: String!,
        $pseudoTiersSlug: [String]!,
        $first: Int!,
        $after: String!,
      ) {
        projectType: allTags(text:$projectTypeText, parent_Parent_Slug: $rootSlug) {
          edges{
            node{
              slug
              placementBrands (first: $first, after: $after, pseudoTiersSlug: $pseudoTiersSlug){
                edges{
                  node{
                    pseudoTierSlug
                    totalBrandsCount
                    brands {
                      djangoId
                      name
                      productsCount
                      slug
                      isPaid
                      connectedProjectsCount
                      logoUrl
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        projectTypeText: activeProjectType.text,
        rootSlug: PROJECT_TYPE_ROOT_SLUG,
        pseudoTiersSlug: pseudoTiersToFetch,
        first: BRANDS_COUNT,
        after: '0',
      },
    });

    APIClient('', true, body).then((data) => {
      try {
        if ('errors' in data) {
          throw new Error(data.errors);
        }
        const currentActiveProjectType = this.getActiveProjectType();
        const { placementBrands = [] } = data.projectType[0];
        const brandsToTrack = [];

        placementBrands.forEach(({ pseudoTierSlug, brands, totalBrandsCount }) => {
          const pt = currentActiveProjectType.pseudoTiers.find(projectPT => (
            projectPT.slug === pseudoTierSlug
          ));
          if (pt) {
            if (!pt.brands || !pt.brands.length) {
              pt.brands = this.prepareBrands(brands);
              pt.totalBrandsCount = totalBrandsCount;
              pt.isLoading = false;
              pt.isLoaded = true;

              brandsToTrack.push(...brands.map(brand => ({
                brand: brand.djangoId,
                pseudotier: pt.slug,
              })));
            }
          }
        });

        this.sendAnalytics({ brands: brandsToTrack });

        this.updateProjectType(currentActiveProjectType);
      } catch (err) {
        // Unable to retrieve brands for Pseudotiers of Project Type,
        // send MODAL_OPEN event only if it hasn't been sent in getPseudotiersForProjectType.
        if (this.isNewItem) {
          this.sendAnalytics();
        }
        Raven.captureException(err);
      }
    });
  }

  getActiveProjectType = () => cloneDeep(
    this.state.projectTypes[this.state.activeProjectTypeText],
  ) || defaultProjectType;

  /**
   * Returns the props for analytics events (MODAL_OPEN and BRANDS_EXPOSURE), parse the exposed
   * brands, pseudotiers, and products as comma separated strings.
   * All of the 3 outputed properities have the same length which equals to the longest input, where
   * when the length is different, item will be set as empty string, the purpose for this is to
   * make it easy to unpack and connect the 3 properities in analytics reports.
   *
   * @param {Array.<Object.<{brand: String, pseudotier: String}>>} brands - brands and their
   * pseudotiers that needs to be tracked.
   * @param {Array.<Number>} products - products that needs to be tracked
   *
   * @returns {String} source - static string to indicate the sender: Brands.
   * @returns {String} exposed_brands - comma-separated string of the brand IDs that got exposed
   * @returns {String} exposed_pseudotiers - comma-separated string of the exposed brand's
   * pseudotiers.
   * @returns {String} exposed_products - comma-separated string of the exposed brand's products.
   */
  prepareAnalyticsProps = ({ brands = [], products = [] } = {}) => {
    const pseudotiersArray = [];
    const brandsArray = [];
    const productsArray = [];

    range(brands.length >= products.length ? brands.length : products.length).forEach((i) => {
      const { brand = '', pseudotier = '' } = brands[i] || {};
      brandsArray.push(brand);
      pseudotiersArray.push(pseudotier);
      productsArray.push(products[i] || '');

      // Mark pseudotiers as tracked, so that they don't get tracked again in getPseudoTiersToFetch
      this.trackedPseudotiers[pseudotier] = true;
    });

    return {
      section: BRAND_EXPOSURE_SECTIONS.BRANDS_LEADERBOARD,
      action: BRAND_EXPOSURE_ACTIONS.IMPRESSION,
      exposed_project_type: this.state.activeProjectTypeText,
      exposed_pseudotiers: pseudotiersArray.join(','),
      exposed_brands: brandsArray.join(','),
      exposed_products: productsArray.join(','),
    };
  }

  trackPreviouslyFetchedBrands = (projectType) => {
    const { pseudoTiers = [], activePseudoTierIndex = 0 } = projectType || {};
    if (!pseudoTiers.length) {
      return;
    }
    const brandsToBeTracked = [];

    range(this.visibleBlocks).forEach((i) => {
      const { brands = [], slug = null } = pseudoTiers[activePseudoTierIndex + i] || {};
      brandsToBeTracked.push(...brands.map(brand => ({ pseudotier: slug, brand: brand.djangoId })));
    });

    this.sendAnalytics({ brands: brandsToBeTracked });
  }

  sendAnalytics = ({ brands = [], products = [] } = {}) => {
    const props = this.prepareAnalyticsProps({ brands, products });

    // If this is the first event for this item, then send open event
    if (this.isNewItem) {
      this.isNewItem = false;
      this.props.triggerOpenEvent(props);
    } else {
      this.props.segment.track(ANALYTICS_NAME.BRANDS_EXPOSURE, props);
    }
  }

  prepareBrands = brands => brands.map(brand => ({
    ...brand,
    logoUrl: brand.logoUrl
      ? prepareImgixParams(brand.logoUrl, { w: 24, h: 24, auto: 'format,compress' })
      : defaultLogoImageUrl,
  }));

  calcVisibleBlocksCount = () => {
    try {
      const container = document.getElementById(RESULTS_CONTAINER_ID);
      const containerHeight = container ? container.offsetHeight : window.innerHeight - 375 || 600;
      const blockHeight = container && container.children && container.children.length
        ? container.children[0].offsetHeight
        : 150;

      this.visibleBlocks = Math.ceil(containerHeight / blockHeight);
    } catch (err) {
      Raven.captureException(err);
    }
  }

  updateProjectType = (projectTypeClone, callback = () => {}) => {
    if (!projectTypeClone) {
      return;
    }

    const projectTypesClone = cloneDeep(this.state.projectTypes);
    projectTypesClone[projectTypeClone.text] = projectTypeClone;

    this.setState({ projectTypes: projectTypesClone }, callback);
  };

  scrollPseudoTiersNavToActive = () => {
    const { offsetLeft } = document.getElementById(`link-${scroller.getActiveLink()}`) || {};
    const pseudotiersNav = document.getElementById(PSEUDOTIERS_NAV_ID);
    if (!Number.isNaN(offsetLeft) && pseudotiersNav) {
      pseudotiersNav.scrollLeft = offsetLeft;
    }
  }

  scrollPseudoTierBrandsTo = name => scroller.scrollTo(name, {
    containerId: RESULTS_CONTAINER_ID,
    smooth: 'easeInOutQuart',
    duration: 150,
  });

  // Next & previous arrows
  movePseudoTier = (step) => {
    const activeProjectType = this.getActiveProjectType();
    const totalLength = activeProjectType.pseudoTiers.length;

    if (!totalLength) {
      return;
    }

    let newIndex = (activeProjectType.activePseudoTierIndex + step) % totalLength;
    newIndex = newIndex < 0 ? totalLength - 1 : newIndex;

    activeProjectType.activePseudoTierIndex = newIndex;
    this.scrollPseudoTierBrandsTo(activeProjectType.pseudoTiers[newIndex].slug);

    this.updateProjectType(activeProjectType);
  }

  nextPseudoTier = () => this.movePseudoTier(1);

  previousPseudoTier = () => this.movePseudoTier(-1);

  // Scrolling results panel
  handleSetActive = (activeSlug) => {
    const activeProjectType = this.getActiveProjectType();
    if (activeProjectType.pseudoTiers.length) {
      const index = activeProjectType.pseudoTiers.findIndex(pt => pt.slug === activeSlug);
      if (index !== -1) {
        activeProjectType.activePseudoTierIndex = index;
        this.updateProjectType(activeProjectType, this.getPseudoTiersBrands);
        this.scrollPseudoTiersNavToActive();
      }
    }
  }

  defaultState = () => ({
    isLoading: false,
    activeSection: SECTIONS.BRANDS,
    projectTypes: {},
    activeProjectTypeText: null,
  });

  render() {
    const { projectTypeText, parentIsLoading } = this.props;
    const { activeSection, isLoading } = this.state;

    const activeProjectType = this.getActiveProjectType();
    const {
      pseudoTiers = [],
    } = activeProjectType;

    return (
      <div className="grix-x">
        {/* Header & Sections */}
        <Header>
          <Title>
            {projectTypeText && projectTypeText !== NO_PROJECT_TYPE
              ? `${projectTypeText} Projects`
              : <>&nbsp;</>}
          </Title>
          <Ul>
            {Object.values(SECTIONS).map(section => (
              <li key={section} className="display-inline-block fs-s mr-base unselectable">
                <a
                  href={null}
                  className={section === activeSection ? 'active black p-r' : 'gray-300'}
                  onClick={() => this.setState({ activeSection: section }, this.props.refreshAd)}
                >
                  {section}
                  {section === activeSection ? <TriangleDown /> : <></>}
                </a>
              </li>
            ))}
          </Ul>
        </Header>
        {/* Pseudo Tiers & Brands */}
        <>
          {/* Loading State */}
          {pseudoTiers.length === 0 && (isLoading || parentIsLoading)
            && <div className="p-base"><div className="loading-spinner-m m-auto" /></div>}
          {/* No Results State */}
          {pseudoTiers.length === 0 && !parentIsLoading && !isLoading
            && (
            <div className="p-base gray-300">
              <i>No matched brands</i>
            </div>
            )}
          {/* Results State */}
          {pseudoTiers.length > 0 && (
          <>
            {/* Pseudo Tiers Navigation */}
            <div className="cell mt-s">
              <PseudoTierNavArrow onClick={this.previousPseudoTier}>
                <i className="material-icons fs-l">keyboard_arrow_left</i>
              </PseudoTierNavArrow>
              <PseudoTiersUl>
                {pseudoTiers.map(pt => (
                  <PseudoTiersLi key={pt.slug}>
                    <PseudoTierLink
                      activeClass="fw-bold black bg-extra-light-gray"
                      id={`link-${pt.slug}`}
                      to={pt.slug}
                      spy
                      smooth
                      duration={250}
                      containerId={RESULTS_CONTAINER_ID}
                      onSetActive={this.handleSetActive}
                      isDynamic
                    >
                      {pt.name}
                    </PseudoTierLink>
                  </PseudoTiersLi>
                ))}
              </PseudoTiersUl>
              <PseudoTierNavArrow onClick={this.nextPseudoTier}>
                <i className="material-icons fs-l">keyboard_arrow_right</i>
              </PseudoTierNavArrow>
            </div>
            <hr className="border-gunmetal-400 mt-xs mb-0" />
            {/* Pseudo Tiers Brands */}
            <PseudoTiersResults
              id={RESULTS_CONTAINER_ID}
              name={RESULTS_CONTAINER_ID}
            >
              {pseudoTiers.map((pt, i) => (
                <PseudoTierBrands
                  key={pt.slug}
                  index={i}
                  pseudoTier={pt}
                  segment={this.props.segment}
                  projectType={projectTypeText}
                />
              ))}
            </PseudoTiersResults>
            <hr className="border-gunmetal-400 mt-0 mb-xs" />
          </>
          )}
        </>
      </div>
    );
  }
}

BrandsLeaderboard.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  projectTypeText: PropTypes.string,
  segment: PropTypes.instanceOf(SegmentClient),
  triggerOpenEvent: PropTypes.func,
  parentIsLoading: PropTypes.bool,
  refreshAd: PropTypes.func,
};

BrandsLeaderboard.defaultProps = {
  id: null,
  projectTypeText: null,
  segment: new SegmentClient(),
  triggerOpenEvent: () => {},
  parentIsLoading: false,
  refreshAd: () => {},
};
