/* --- Polyfills --- */
import 'core-js/es/map';
import 'core-js/es/set';

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';

import IdeaDetails from '../components/idea/IdeaDetails';
import BrandPhotoDetails from '../components/brand_photos/BrandPhotoDetails';
import CollectionMediaDetails from '../components/collections/CollectionMediaDetails';
import {
  ANALYTICS_NAME as IDEA_ANALYTICS_NAME,
  BASE_URL as IDEA_BASE_URL,
} from '../components/idea/Constants';
import {
  ANALYTICS_NAME as COLLECTIONS_ANALYTICS_NAME,
  BASE_URL as COLLECTIONS_BASE_URL,
  MEDIA_BASE_URL as COLLECTIONS_MEDIA_BASE_URL,
} from '../components/collections/Constants';
import {
  ANALYTICS_NAME as BRAND_PHOTOS_ANALYTICS_NAME,
} from '../components/brand_photos/Constants';
import {
  CALL_SOURCES,
} from '../components/universal_modal/Constants';
import SegmentClient from '../services/SegmentClient';
import AddToCollection from '../components/collections/AddToCollections';
import Popup, { PopupSizes } from '../components/Popup';
import MediaCredit from '../components/media_credit/MediaCredit';
import BrandMessage from '../components/brand_message/BrandMessage';
import NotFound from '../components/NotFound';
import GenericMessage from '../components/GenericMessage';
import { architizerHistoryListener } from '../services/utils';
import GA4Client from '../services/GA4Client';

const ga4 = new GA4Client();

const { UNIVERSAL_MODAL } = window;
const TYPES = UNIVERSAL_MODAL.TYPE;
const root = document.getElementById(UNIVERSAL_MODAL.ROOT);

const modalOverriddenStyle = {
  overlay: {
    zIndex: 999,
  },
  content: {
    width: '100%',
    maxWidth: '100%',
    top: 0,
    height: '100%',
    borderRadius: 0,
    padding: 0,
  },
};

const popupOverriddenStyle = {
  overlay: {
    overflowY: 'visible',
  },
  content: {
    overflowY: 'visible',
  },
};

const POPUP_TYPES = [
  TYPES.ADD_TO_COLLECTION,
  TYPES.MEDIA_CREDIT,
  TYPES.BRAND_MESSAGE,
  TYPES.NOT_FOUND,
  TYPES.GENERIC_MESSAGE,
];

const MODALS_DATA = {
  [TYPES.MEDIA_ITEM]: {
    URL_PATTERN: `^/${IDEA_BASE_URL}/([0-9]+)/?$`,
    ANALYTICS_NAME: IDEA_ANALYTICS_NAME,
  },
  [TYPES.COLLECTIONS]: {
    URL_PATTERN: `^/${COLLECTIONS_BASE_URL}/[-\\w\\d_]+/${COLLECTIONS_MEDIA_BASE_URL}/([0-9]+)/?$`,
    ANALYTICS_NAME: COLLECTIONS_ANALYTICS_NAME,
  },
  [TYPES.BRAND_PHOTO]: {
    ANALYTICS_NAME: BRAND_PHOTOS_ANALYTICS_NAME,
  },
  // Add other universal URL content patterns here
};

const universalUrlsRegex = new RegExp(Object.entries(MODALS_DATA).filter(d => d[1].URL_PATTERN).map(d => d[1].URL_PATTERN).join('|'));

class UniversalModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState(),
      userId: window.userId,
    };

    this.segment = new SegmentClient({
      userId: window.userId,
    });
    this.ANALYTICS_NAME = {};
  }

  componentDidMount() {
    if (!root) {
      return;
    }

    this.historyUnlistener = this.props.history.listen(architizerHistoryListener((
      location,
      action,
    ) => {
      // Support next/previous using browser back/forward
      if (action === 'POP') {
        this.openModalFromPath(location.pathname);
      }
    }));

    root.addEventListener(UNIVERSAL_MODAL.EVENT_NAME, (event) => {
      const {
        action,
        type,
        items,
        callSource,
        extraAnalyticsInfo,
        brandSlug,
        dispatcherPath,
        mediaAttrId,
        mediaIds,
        productId,
        productName,
        collectionSlug,
        notfoundCallback,
        callback,
        title,
        body,
        close_button,
      } = event.detail ? event.detail : {};

      if (action === UNIVERSAL_MODAL.OPEN) {
        // Universal modals
        if (!POPUP_TYPES.includes(type)) {
          if (items && items.length) {
            this.setState({
              type,
              items,
              dispatcherPath: dispatcherPath || window.location.pathname,
              callSource,
              brandSlug,
              collectionSlug,
              notfoundCallback,
              callback,
              extraAnalyticsInfo,
            }, () => this.open(false, type));
          }
        // Universal popups
        } else {
          this.setState({
            popupType: type,
            mediaAttrId,
            mediaIds,
            callback,
            title,
            body,
            productId,
            productName,
            close_button,
          }, () => {
            this.openPopup();
          });
        }
      } else if (action === UNIVERSAL_MODAL.CLOSE) {
        if (!POPUP_TYPES.includes(type)) {
          this.setState({ items: [] }, () => this.close());
        } else {
          this.closePopup();
        }
      }
    }, false);
  }

  componentWillUnmount() {
    root.removeEventListener(UNIVERSAL_MODAL.EVENT_NAME);
    if (typeof this.historyUnlistener === 'function') {
      this.historyUnlistener();
    }
  }

  getContentMatchers = (pathname) => {
    const matchers = {};

    Object.entries(MODALS_DATA).forEach((d) => {
      matchers[d[0]] = pathname.match(d[1].URL_PATTERN);
    });

    return matchers;
  }

  getContentDetailsFromPath = (pathname) => {
    const matchers = this.getContentMatchers(pathname);
    if (matchers[TYPES.MEDIA_ITEM] && matchers[TYPES.MEDIA_ITEM].length) {
      return {
        index: matchers[TYPES.MEDIA_ITEM][1],
        type: TYPES.MEDIA_ITEM,
      };
    } if (matchers[TYPES.COLLECTIONS] && matchers[TYPES.COLLECTIONS].length) {
      return {
        index: matchers[TYPES.COLLECTIONS][1],
        type: TYPES.COLLECTIONS,
      };
    } // Add other universal URL content patterns here

    return {
      index: null,
      type: null,
    };
  }

  getContentComponent = () => {
    const { type } = this.state;

    if (type === TYPES.MEDIA_ITEM) {
      return (
        <IdeaDetails
          items={this.state.items}
          isUniversalModal
          close={this.close}
          extraAnalyticsInfo={this.state.extraAnalyticsInfo}
          pushHistory={this.pushHistory}
          callSource={this.state.callSource}
          isOpen={this.state.isOpen}
        />
      );
    } if (type === TYPES.COLLECTIONS) {
      return (
        <CollectionMediaDetails
          items={this.state.items}
          close={this.close}
          extraAnalyticsInfo={this.state.extraAnalyticsInfo}
          pushHistory={this.pushHistory}
          isOpen={this.state.isOpen}
          collectionSlug={this.state.collectionSlug}
          notfoundCallback={this.state.notfoundCallback}
        />
      );
    } if (type === TYPES.BRAND_PHOTO) {
      return (
        <BrandPhotoDetails
          items={this.state.items}
          close={this.close}
          extraAnalyticsInfo={this.state.extraAnalyticsInfo}
          pushHistory={this.pushHistory}
          callSource={this.state.callSource}
          isOpen={this.state.isOpen}
          brandSlug={this.state.brandSlug}
        />
      );
    } // else if (type === TYPES.NEW_TYPE) {}
    // Implement other modal content types here
    return <></>;
  }

  getPopupComponent = () => {
    const { popupType, title } = this.state;
    const result = {};

    if (popupType === TYPES.ADD_TO_COLLECTION) {
      result.popupContent = (
        <AddToCollection
          mediaItemAttId={this.state.mediaAttrId}
        />
      );

      result.popupHeader = 'Add to Collections';
    } else if (popupType === TYPES.MEDIA_CREDIT) {
      result.popupContent = (
        <MediaCredit
          mediaIds={this.state.mediaIds}
          close={this.closePopup}
          callback={this.state.callback}
        />
      );

      result.popupHeader = 'Image Credits';
    } else if (popupType === TYPES.BRAND_MESSAGE) {
      result.popupContent = (
        <BrandMessage
          productId={this.state.productId || null}
          productName={this.state.productName || null}
          close={this.closePopup}
          callback={this.state.callback}
        />
      );
      result.customStyle = {
        overlay: {
          overflowY: 'scroll',
          zIndex: 2000,
        },
        content: {
          overflowY: 'scroll',
        },
      };
      result.popupHeader = 'Send a Message';
    } else if (popupType === TYPES.NOT_FOUND) {
      result.popupContent = (
        <NotFound
          close={this.closePopup}
          title={this.state.title}
          body={this.state.body}
          close_button={this.state.close_button}
        />
      );
      result.popupHeader = title;
    } else if (popupType === TYPES.GENERIC_MESSAGE) {
      result.popupContent = (
        <GenericMessage
          close={this.closePopup}
          title={this.state.title}
          body={this.state.body}
          close_button={this.state.close_button}
        />
      );
      result.popupHeader = title;
    }
    // Implement other popup types here

    return result;
  };

  openModalFromPath = (pathname) => {
    if (this.isUniversalPath(pathname)) {
      const { index, type } = this.getContentDetailsFromPath(pathname);
      if (index) {
        this.open(true, type);
        this.updateItemsActiveIndex(index);

        // Do not trigger page view for requests coming from Merlin because it has a router that
        // handles triggers page view for browser forward/back navigation.
        if (![
          CALL_SOURCES.PROJECT_DETAILS,
          CALL_SOURCES.COLLECTIONS,
        ].includes(this.state.callSource)) {
          this.segment.page();
          ga4.page();
        }
      }
    } else {
      this.close();
    }
  }

  isUniversalPath = pathname => universalUrlsRegex.test(pathname);

  historyUnlistener;

  updateItemsActiveIndex = id => this.setState({
    items: this.state.items.find(item => item.id === id) === undefined
      // When items list doesn't contain <id> (i.e. clicking similar image),
      // then create new items list with <id> only
      ? [{ id, isActive: true }]
      : this.state.items.map(item => ({ ...item, isActive: item.id === id })),
  });

  open = (checkIfIsOpenNull = false, type = null) => {
    const { isOpen, items, extraAnalyticsInfo } = this.state;

    // Trigger open event for all modals except MEDIA_ITEM and COLLECTIONS
    // because it's being triggered from the component itself.
    if (type && MODALS_DATA[type] && type !== TYPES.MEDIA_ITEM && type !== TYPES.COLLECTIONS) {
      this.segment.track(MODALS_DATA[type].ANALYTICS_NAME.MODAL_OPENED, {
        type,
        ...extraAnalyticsInfo,
      });
    }

    // Do not open if isOpen equals null, because this means that a browser back
    // button is triggered from idea "page", which this should not react to.
    if (checkIfIsOpenNull && isOpen === null) {
      return;
    }

    if (isOpen !== true && items && items.length) {
      this.setState({ isOpen: true, parentPageTitle: document.title });
    }
  };

  openPopup = () => {
    if (!this.state.isPopupOpen) {
      this.setState({ isPopupOpen: true });
    }
  };

  close = (pushDispatcherPath = false) => {
    const { isOpen, parentPageTitle } = this.state;
    if (isOpen !== false) {
      this.setState({ isOpen: false }, () => {
        if (parentPageTitle) {
          document.title = parentPageTitle;
        }
        if (pushDispatcherPath) {
          this.pushHistory(this.state.dispatcherPath, false);
        }
      });
    }
  };

  closePopup = () => {
    if (this.state.isPopupOpen) {
      this.setState({ isPopupOpen: false, popupType: null });
    }
  };

  defaultState = () => ({
    dispatcherPath: null,
    userId: null,
    isOpen: null,
    isPopupOpen: false,
    items: [],
    count: 0,
    extraAnalyticsInfo: {},
    type: null,
    popupType: null,
    brandSlug: null,
    parentPageTitle: null,
    sendTrackPromiseResolve: null,
    callback: () => {},
  });

  pushHistory = (url, countPageView = true) => {
    if (!url || url === window.location.pathname) {
      return;
    }

    this.props.history.push(url, {
      previousPage: {
        title: document.title, url: window.location.href,
      },
    });
    if (countPageView) {
      this.segment.page();
      ga4.page();
    }
  };

  render() {
    const contentComponent = this.getContentComponent();
    const { popupHeader, popupContent, customStyle } = this.getPopupComponent();
    return (
      <>
        <Modal
          isOpen={Boolean(this.state.isOpen)}
          onRequestClose={() => this.close(true)}
          className="reveal"
          overlayClassName="reveal-overlay"
          shouldCloseOnEsc
          shouldCloseOnOverlayClick={false}
          style={modalOverriddenStyle}
          parentSelector={() => root}
        >
          {contentComponent}
        </Modal>
        {popupContent
        && (
        <Popup
          isOpen={this.state.isPopupOpen}
          onRequestClose={() => this.closePopup()}
          className="reveal modal-width-l"
          overlayClassName="reveal-overlay"
          shouldCloseOnOverlayClick
          parentSelector={() => root}
          header={popupHeader}
          size={PopupSizes.medium}
          style={customStyle || popupOverriddenStyle}
        >
          {popupContent}
        </Popup>
        )}
      </>
    );
  }
}

UniversalModal.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({}).isRequired,
    listen: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

if (root) {
  Modal.setAppElement(root);
  ReactDOM.render(
    <BrowserRouter>
      <Route component={withRouter(UniversalModal)} />
    </BrowserRouter>,
    root,
  );
}
