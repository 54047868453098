import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TooltipTrigger from 'react-popper-tooltip';

const Tooltip = ({
  tooltip, children, hideArrow, small, large, clickable, offset, ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      getTooltipProps,
      getArrowProps,
      tooltipRef,
      arrowRef,
      placement,
    }) => (
      <div className={classNames('adk')}>
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames(
              'adk',
              'tooltip-container',
              `tooltip-offset-${offset}`,
              { 'ts-s': small },
              { 'ts-l': large },
            ),
          })}
        >
          {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              'data-placement': placement,
              className: 'tooltip-arrow',
            })}
          />
          )}
          {tooltip}
        </div>
      </div>
    )}
    trigger={clickable ? 'click' : 'hover'}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

Tooltip.propTypes = {
  tooltip: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideArrow: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  clickable: PropTypes.bool,
  offset: PropTypes.oneOf(['s', 'm', 'l']),
};
Tooltip.defaultProps = {
  hideArrow: false,
  small: false,
  large: false,
  clickable: false,
  offset: 's',
};

export default Tooltip;
