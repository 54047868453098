import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationSystem from 'react-notification-system';
import { MEDIA_QUERIES } from '../../services/constants';
import { UserResource } from '../../services/RESTClient';
import APIClient from '../../services/APIClient';
import { errorNotificationSettings, notificationStyles } from '../../services/utils';

const ACTION_TYPES = {
  ADD: 'ADD',
  DELETE: 'DELETE',
};

const errorUpdateMedia = Object.assign(
  {},
  errorNotificationSettings,
  { message: 'Couldn\'t add/remove media item to/from the collection, Please try again' },
);

const errorCreateCollection = Object.assign(
  {},
  errorNotificationSettings,
  { message: 'couldn\'t create a new collection, Please try again' },
);

const errorInvalidCollectionTitle = Object.assign(
  {},
  errorNotificationSettings,
  { message: 'Collection name cannot contain an email, website, phone number or special character' },
);

const userResource = new UserResource();

const AddToCollectionContainer = styled.div`
  && {
    max-height: calc(100vh - 20rem);
    overflow: auto;
    @media (max-width: ${MEDIA_QUERIES.small}) {
      max-height: 80vh;
    }
  }
`;

const AddNewCollectionContainer = styled.div.attrs({
  className: 'row border-global-separator pt-s mb-xs collapse',
})`
  margin-top: auto;
  border-top: 1px solid;
`;

const CollectionCheckbox = styled.div`
  && {
    label {
      display: flex;
      align-items: flex-start;
      
      .success-message {
        animation-name: success-collection;
        animation-duration: 2s;
        opacity: 0;
       }
      
      span.checkbox {
        flex-shrink: 0;
        margin-top: 2px;
        display: inline-block;
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 12px;
        border: 1px solid #B8C8D1;
        border-radius: 2px;
        background: white;
        
        &:after {
          content: 'q';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          text-align: center;
          color: rgba(184,200,209,0.5);
          opacity: 0;
          font-family: "Architizer Glyphs" !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &:hover {
        span.checkbox {
          border-color: #00A2E1;
        }
      }
    }
    
    
    input:checked + label span.checkbox:after {
      color: #1CA3FC;
      opacity: 1;
    }
  }
  
  @keyframes success-collection {
      0% {
        opacity: 0
      }
      25% {
        opacity: 1;
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
`;

const CollectionsWrapper = styled.div`
  && {
    .new-collection {
      animation: new-row .4s
    }
    
    label {
      color: #474645;
      font-size: 0.875rem;
    }
    
    @keyframes new-row {
      from {
        background-color: rgba(28, 163, 252, 0.6);
      }
      to {
        background-color: initial;
      }
    }
  }
`;

class AddToCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.state.isLoading = true;

    this.collectionsWrapperRef = React.createRef();

    userResource.me()
      .then((user) => {
        const userId = user.data.id;
        const promises = [];

        promises.push(this.getCollections(userId, this.props.mediaItemAttId));

        promises.push(this.getCollections(userId));

        Promise.all(promises)
          .then(([userMediaCollectionsData, userCollectionsData]) => {
            const checkedCollection = userCollectionsData.allCollections.map(collection => ({
              ...collection,
              // eslint-disable-next-line max-len
              isChecked: userMediaCollectionsData.allCollections.findIndex(col => col.djangoId === collection.djangoId) >= 0,
            }));

            this.setState({
              collections: checkedCollection,
              isLoading: false,
            });
          }).catch(() => this.setState({ isLoading: false }));
      });
  }

  onSuccessAddition = (id, action = ACTION_TYPES.ADD) => {
    if (!id) {
      return;
    }

    this.setState({
      successMessageColIds: {
        ...this.state.successMessageColIds,
        [id]: action === ACTION_TYPES.ADD ? 'Added' : 'Deleted',
      },
    }, () => {
      setTimeout(() => {
        const successMessageColIds = { ...this.state.successMessageColIds };
        delete successMessageColIds[id];
        this.setState({
          successMessageColIds,
        });
      }, 2000);
    });
    window.imageActionsTracker('add-to-collection', { id: this.props.mediaItemAttId });
  }

  getCollections = (userId, mediaId) => {
    const query = `{
      allCollections (status: "published", selfStatus: "published", userId: ${userId}${mediaId ? `, mediaAttrId: ${mediaId}` : ''}) {
        edges {
         node {
          djangoId
          ${!mediaId ? 'title' : ''}
         }
        }
      }
    }
  `;

    return APIClient(query, true);
  };

  addDeleteMediaInCollection = (originalCollection) => {
    const action = originalCollection.isChecked ? ACTION_TYPES.DELETE : ACTION_TYPES.ADD;

    const mutation = `mutation {
        addRemoveMediaCollection(input: {miaId:${this.props.mediaItemAttId}, collectionId: ${originalCollection.djangoId}, action: "${action}"}) {
          created
          deleted
        }
      }
  `;

    const col = { ...originalCollection };

    col.isChecked = !col.isChecked;
    // create new reference for the updated collection and the collections array
    this.setState({
      collections: this.state.collections.map((collection) => {
        if (collection.djangoId === col.djangoId) {
          return col;
        }
        return collection;
      }),
    });

    APIClient(mutation)
      .then(response => response.json())
      .then((response) => {
        if ('errors' in response) {
          // create new reference for the updated collection and the collections array
          this.setState({
            collections: this.state.collections.map((collection) => {
              if (collection.djangoId === col.djangoId) {
                return originalCollection;
              }
              return collection;
            }),
          });
          this.notificationSystem.addNotification(errorUpdateMedia);
          Raven.captureException(JSON.stringify(response));
        } else {
          this.onSuccessAddition(col.djangoId, action);
        }
        return response.data;
      });
  };

  createCollection = () => {
    const mutation = `mutation($title: String!, $miaId: Int)  {
        createCollection(input: {
          title: $title,
          miaId: $miaId
        }) {
          collection {
            djangoId
            title
          }
          isChecked
        }
      }
  `;

    const body = JSON.stringify({
      query: mutation,
      variables: {
        title: this.state.newCollectionTitle,
        miaId: this.state.addToNewCollection && this.props.mediaItemAttId,
      },
    });

    this.setState({ isCreatingCollection: true });

    APIClient('', true, body)
      .then((response) => {
        if ('errors' in response) {
          this.notificationSystem.addNotification(errorCreateCollection);
          Raven.captureException(JSON.stringify(response));
        } else {
          const { isChecked } = response.createCollection;
          const collection = {
            ...response.createCollection.collection,
            isChecked,
            isNew: true,
          };
          this.setState({
            collections: [
              ...this.state.collections,
              collection,
            ],
            newCollectionTitle: '',
            addToNewCollection: false,
          }, () => {
            if (response.createCollection.isChecked) {
              this.onSuccessAddition(collection.djangoId);
            }
            setTimeout(() => {
              collection.isNew = false;
              this.setState({
                collections: this.state.collections.map((col) => {
                  if (col.djangoId === collection.djangoId) {
                    return { ...collection };
                  }
                  return col;
                }),
              });
            }, 500);
          });
          const colWrapperElem = this.collectionsWrapperRef.current;
          colWrapperElem.scrollTop = colWrapperElem.scrollHeight;
        }
        this.setState({ isCreatingCollection: false });
        return response.data;
      }).catch(error => {
        if (error.toString().indexOf('invalid_title_special_chars') !== -1) {
          this.notificationSystem.addNotification(errorInvalidCollectionTitle);
          this.setState({ isCreatingCollection: false });
          return;
        }
        this.notificationSystem.addNotification(errorCreateCollection);
        this.setState({ isCreatingCollection: false });
      });
  };

  defaultState = () => ({
    collections: [],
    isLoading: false,
    isCreatingCollection: false,
    newCollectionTitle: '',
    successMessageColIds: {},
    addToNewCollection: false,
  });

  render() {
    const notificationSystemRef = (ref) => { this.notificationSystem = ref; };
    return (
      <>
        <AddToCollectionContainer className="flex-container flex-dir-column pb-base">
          {this.state.isLoading && <h4 className="text-center mb-s">Loading...</h4>}
          {!this.state.isLoading && !this.state.collections.length
          && (
          <h4 className="text-center mb-s">
            <p>You have no collections</p>
            <small>Please create a new collection</small>
          </h4>
          )}
          {!this.state.isLoading && Boolean(this.state.collections.length)
            && (
            <CollectionsWrapper ref={this.collectionsWrapperRef} className="mb-base">
              {this.state.collections.map(collection => (
                <CollectionCheckbox key={collection.djangoId}>
                  <input
                    id={`col${collection.djangoId}`}
                    className="m-0 mr-xs mt-micro hide"
                    checked={collection.isChecked}
                    onChange={() => this.addDeleteMediaInCollection(collection)}
                    type="checkbox"
                  />
                  <label
                    className={`m-0 cursor-pointer pt-xs pb-xs ${collection.isNew ? 'new-collection' : ''}`}
                    htmlFor={`col${collection.djangoId}`}
                    key={collection.djangoId}
                  >
                    <span className="checkbox" />
                    <span>{collection.title}</span>
                    {this.state.successMessageColIds[collection.djangoId]
                      && <span className="success-message green-600 ml-base">{this.state.successMessageColIds[collection.djangoId]}</span>}
                  </label>
                </CollectionCheckbox>
              ))}
            </CollectionsWrapper>
            )}
          <NotificationSystem
            ref={notificationSystemRef}
            noAnimation
            style={notificationStyles}
          />
        </AddToCollectionContainer>
        <AddNewCollectionContainer>
          <div className="columns shrink">
            <CollectionCheckbox>
              <input
                id="add-to-new-collection"
                className="m-0 mr-xs mt-micro hide"
                checked={this.state.addToNewCollection}
                onChange={e => this.setState({ addToNewCollection: e.target.checked })}
                type="checkbox"
              />
              <label className="m-0 pt-xs cursor-pointer" htmlFor="add-to-new-collection">
                <span className="checkbox" />
              </label>
            </CollectionCheckbox>
          </div>
          <div className="columns pr-xs">
            <input
              className="m-0"
              placeholder="New collection"
              style={{ padding: '.245rem', height: 'auto' }}
              type="text"
              value={this.state.newCollectionTitle}
              onChange={e => this.setState({ newCollectionTitle: e.target.value })}
            />
          </div>
          <div className="columns shrink">
            <button
              className="button secondary"
              disabled={this.state.isCreatingCollection || !this.state.newCollectionTitle}
              onClick={() => this.createCollection()}
              type="button"
            >
              Create
            </button>
          </div>
        </AddNewCollectionContainer>
      </>
    );
  }
}

AddToCollection.propTypes = {
  mediaItemAttId: PropTypes.string.isRequired,
};

AddToCollection.defaultProps = {
};

export default AddToCollection;
