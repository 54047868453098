import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ShareDialog from '../ShareDialog';
import SegmentClient from '../../services/SegmentClient';
import { UserResource } from '../../services/RESTClient';
import { PERMISSION_TYPES } from '../RequireRegisterPopup';

const userResource = new UserResource();

class ImageActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
  }

  componentDidMount() {
    userResource.me()
      .finally(() => this.setState({
      }));
  }

  getBrowseImageUrl = () => {
    const { item } = this.props;
    return `/browse/images/${item.mediaAttributionId}`;
  }

  defaultState = () => ({
  });

  render() {
    const {
      item,
      ANALYTICS_NAME,
      segment,
      callSource,
      edit,
      collectable,
      openRequireRegister,
    } = this.props;

    const canShare = userResource.isAuthenticated()
      && (!userResource.notVerified() || userResource.isGod());
    const canSave = userResource.isNonProfessional()
      || userResource.isPendingProfessional()
      || userResource.isProfessional()
      || userResource.isGod();
    const canCollect = userResource.canCollect();
    const viewOriginalUrl = item && `/image-download/${item.mediaAttributionId + (callSource === window.UNIVERSAL_MODAL.TYPE.BRAND_PHOTO ? '?media_type=brand_photo' : '')}`;

    return (
      <>
        {/* Collect */}
        {item && item.mediaAttributionId && collectable && (
        <a
          href={null}
          className="button tiny br-max black-button black-button-transparent pl-xs pr-xs mr-xs"
          onClick={() => {
            if (canCollect) {
              window.openAddToCollectionPopup({ mediaAttrId: item.mediaAttributionId });
            } else {
              openRequireRegister({ permissionType: PERMISSION_TYPES.COLLECT });
            }
          }}
        >
          <i className="material-icons fs-m">playlist_add</i>
          <span className="show-for-medium"> Collect</span>
        </a>
        )}
        {/* View Original */}
        {item && item.mediaAttributionId && (
        <a
          onClick={() => !canSave && openRequireRegister({
            permissionType: PERMISSION_TYPES.SAVE_IMAGE,
            requireRegisterNext: viewOriginalUrl,
          })}
          href={canSave ? viewOriginalUrl : null}
          target="_blank"
          rel="noreferrer"
          className="button tiny br-max black-button black-button-transparent pl-xs pr-xs mr-xs"
        >
          <i className="material-icons fs-m">fullscreen</i>
          <span className="show-for-medium"> View Original</span>
        </a>
        )}
        {/* Share */}
        {item && item.url && (
        <ShareDialog
          disabled={!canShare}
          url={item.url}
          segment={segment}
          hideShareForSmall
          ANALYTICS_NAME={ANALYTICS_NAME}
        />
        )}
        {/* Edit */}
        {item && item.mediaAttributionId && edit && edit.href && (
        <a
          href={edit.href}
          className="button tiny br-max black-button black-button-transparent pl-xs pr-xs mr-xs"
        >
          <i className="material-icons fs-m">edit</i>
          <span className="show-for-medium"> Edit</span>
        </a>
        )}
      </>
    );
  }
}

ImageActions.propTypes = {
  item: PropTypes.shape({}),
  ANALYTICS_NAME: PropTypes.shape({}),
  brand: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  segment: PropTypes.instanceOf(SegmentClient),
  callSource: PropTypes.string,
  edit: PropTypes.shape({
    href: PropTypes.string,
  }),
  collectable: PropTypes.bool,
  openRequireRegister: PropTypes.func,
};

ImageActions.defaultProps = {
  item: {},
  ANALYTICS_NAME: {},
  brand: {},
  callSource: '',
  segment: new SegmentClient(),
  edit: null,
  collectable: true,
  openRequireRegister: () => {},
};

export default ImageActions;
