import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';

import APIClient from '../services/APIClient';
import { SuggesterClient, SearchClient } from '../services/SearchClient';
import { emptyFilterMessage } from './image_search/Constants';
import {
  PUBLISHED,
  DRAFT,
} from '../services/constants';

const StyledSelect = `
  span.Select-multi-value-wrapper {
    display: block;
    
    .Select-input {
      display: block !important;
    }
  }
`;
const StyledSelectAsyncCreatable = styled(Select.AsyncCreatable)`${StyledSelect}`;
const StyledSelectAsync = styled(Select.Async)`${StyledSelect}`;

class FirmSelector extends React.Component {
  constructor(props) {
    super(props);
    this.loadFirmOptions = (query, callback) => {
      if (this.props.searchQueryLength > 0 && query.length < this.props.searchQueryLength) {
        callback(null, { options: [] });
        return;
      }

      // Firm Suggester
      if (this.props.useFirmSuggester) {
        SuggesterClient('firms', query)
          .then(response => response.json())
          .then((data) => {
            const options = data.data.suggest.mySuggester[query].suggestions.map((suggestion) => {
              const payload = JSON.parse(suggestion.payload);
              let firm = null;
              if (payload.architizer_id) {
                firm = { label: suggestion.term, value: payload.architizer_id };
              }
              return firm;
            });
            callback(null, { options });
          });
        return;
      // Firm Selector
      } if (this.props.useFirmSelector) {
        const fq = [`status:(${PUBLISHED} ${DRAFT})`];
        const fl = ['firm_id', 'title'];
        // Remove & character and use generic SOLR query when no input is provided
        const q = query && typeof query === 'string' ? query.replace(/&/g, '') : '*:*';
        SearchClient('firms', q, [], fq, fl)
          .then(response => response.json())
          .then((response) => {
            if ('error' in response.data) {
              callback(response.data.error, { options: [] });
              return;
            }
            callback(null, {
              options: response.data.response.docs.map(({ firm_id, title }) => ({
                label: title,
                value: firm_id,
              })),
            });
          }).catch((e) => {
            if (typeof e === 'string') {
              Raven.captureException(e);
            } else {
              const msg = e.message || e.msg || 'Error while fetching firms for image credit';
              Raven.captureException(msg, { extra: e });
            }
          });
        return;
      }
      // GraphQL
      const allFirms = `query($status: String, $name: String, $type: String){
        results: allFirms(
          first: 10,
          status_In: $status,
          selfStatus_In: $status,
          name_Icontains: $name,
          companyType_CompanyTypeId: $type,
        ) {
          edges {
            node {
              label: name
              value: djangoId
              ${this.props.firmProperties}
            }
          }
        }
      }`;

      const body = JSON.stringify({
        query: allFirms,
        variables: {
          status: this.props.status.join(','),
          name: query,
          type: this.props.type,
        },
      });
      APIClient('', true, body)
        .then(data => callback(null, { options: data.results }));
    };
    this.loadFirmOptionsDebounced = debounce(this.loadFirmOptions, 2000);
  }

  render() {
    const {
      label,
      isCreatable,
      disabled,
      required,
      onChange,
      value,
      placeholder,
      filterOptions,
      filterOption,
      name,
      searchPromptText,
      promptTextCreator,
      optionRenderer,
      clearable,
      resetValue,
    } = this.props;

    const SelectTag = isCreatable ? StyledSelectAsyncCreatable : StyledSelectAsync;

    return (
      <span>
        {label && <label htmlFor="firm_id">{label}</label>}
        <SelectTag
          id="firm_id"
          name={name}
          loadOptions={this.loadFirmOptionsDebounced}
          onChange={option => onChange(option)}
          value={value}
          placeholder={placeholder}
          autosize={false}
          filterOptions={filterOptions}
          filterOption={filterOption}
          promptTextCreator={promptTextCreator}
          required={required}
          optionRenderer={optionRenderer}
          disabled={disabled}
          searchPromptText={searchPromptText}
          noResultsText={emptyFilterMessage}
          clearable={clearable}
          resetValue={resetValue}
        />
      </span>
    );
  }
}

FirmSelector.defaultProps = {
  disabled: false,
  label: '',
  placeholder: undefined,
  filterOptions: undefined,
  filterOption: undefined,
  useFirmSuggester: false,
  useFirmSelector: false,
  searchQueryLength: 0,
  isCreatable: false,
  required: false,
  onChange: undefined,
  promptTextCreator: undefined,
  value: {},
  optionRenderer: undefined,
  name: 'firm',
  searchPromptText: undefined,
  firmProperties: '',
  clearable: true,
  type: null,
  resetValue: null,
  status: [PUBLISHED],
};

FirmSelector.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  searchQueryLength: PropTypes.number,
  name: PropTypes.string,
  firmProperties: PropTypes.string,
  placeholder: PropTypes.string,
  filterOptions: PropTypes.func,
  filterOption: PropTypes.func,
  searchPromptText: PropTypes.string,
  // SOLR Autocomplete, supports fuzzy search but needs to be rebuilt for new values.
  useFirmSuggester: PropTypes.bool,
  // SOLR Select: supports fuzzy search.
  useFirmSelector: PropTypes.bool,
  // GraphQL: uses db to do case insensitive contains matching.  <== default
  isCreatable: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  optionRenderer: PropTypes.func,
  promptTextCreator: PropTypes.func,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  clearable: PropTypes.bool,
  type: PropTypes.string,
  resetValue: PropTypes.shape({}),
  status: PropTypes.arrayOf(PropTypes.string),
};

export default FirmSelector;
