import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Tooltip from '../Tooltip';

const defaultState = () => ({
  creditName: null,
  creditSlug: null,
  isLoading: false,
});

export default class DetailsMediaCredit extends Component {
  static getDerivedStateFromProps(props) {
    return {
      creditName: props.media ? props.media.creditName : null,
      creditSlug: props.media ? props.media.creditSlug : null,
    };
  }

  constructor(props) {
    super(props);
    this.notificationSystem = null;

    this.state = {
      ...defaultState(),
    };
  }

  triggerMediaCreditModal = () => {
    const { media: { mediaId }, callback } = this.props;
    try {
      if (this.props.isEditable) {
        window.openMediaCreditPopup({ mediaIds: [parseInt(mediaId, 10)], callback });
      }
    } catch (e) {
      Raven.captureException(e);
    }
  }

  render() {
    const { media, isEditable } = this.props;
    const { creditName, creditSlug } = this.state;

    if (isEmpty(media) || !media.isLoaded || (!isEditable && !creditName && !creditSlug)) {
      return <></>;
    }

    return (
      <Tooltip
        placement="bottom"
        tooltip={isEditable ? 'Assign Image Credits' : 'View Photographer Profile'}
      >
        <button
          className={`white hover-blue ${isEditable ? 'cursor-pointer' : ''}`}
          style={{ width: 'auto' }}
          onClick={this.triggerMediaCreditModal}
          type="button"
        >
          {creditName
            ? (
              <span>
                <i className="material-icons pr-xxxs p-r" style={{ top: '-2px' }}>copyright</i>
                <a
                  href={isEditable || !creditSlug ? null : `/firms/${creditSlug}/`}
                  className="white hover-blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  {creditName}
                </a>
                {isEditable && (
                <>
                  {' '}
                  <i className="material-icons fs-s">edit</i>
                </>
                )}
              </span>
            )
            : isEditable && (
            <span>
              <i className="fs-s material-icons">add</i>
              {' '}
              Image Credit
              {' '}
              <i className="fs-s material-icons">edit</i>
            </span>
            )}
        </button>
      </Tooltip>
    );
  }
}

DetailsMediaCredit.propTypes = {
  media: PropTypes.shape({
    creditName: PropTypes.string,
    creditSlug: PropTypes.string,
    firmIds: PropTypes.arrayOf(PropTypes.number),
    mediaId: PropTypes.string,
  }),
  callback: PropTypes.func,
  isEditable: PropTypes.bool,
};

DetailsMediaCredit.defaultProps = {
  media: null,
  callback: () => {},
  isEditable: false,
};
