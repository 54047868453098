import React, { Fragment } from 'react';
import { Formik, getIn } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationSystem from 'react-notification-system';
import SegmentClient from '../../services/SegmentClient';

import {
  PHOTO_CREDIT_MESSAGE,
  CREDIT_FIELD_PLACEHOLDER,
  MEDIA_CREDIT_TARGET,
  ANALYTICS_NAME,
} from './Constants';
import FirmSelector from '../FirmSelector';
import validationSchema from './Validation';
import {
  prepareOption,
  notificationStyles,
  successNotificationSettings,
  errorNotificationSettings,
} from '../../services/utils';

import {
  PUBLISHED,
  DRAFT,
} from '../../services/constants';
import {
  MediaResource,
} from '../../services/RESTClient';
import APIClient from '../../services/APIClient';

const CTA = styled.button`
  width: auto;
`;
const Radio = styled.input`
  vertical-align: top;
`;
const RadioLabel = styled.label`
  && {
    display: inline-block;
    width: 85%;
    line-height: 1rem;
  }
`;

const segment = new SegmentClient();

class MediaCredit extends React.Component {
  constructor(props) {
    super(props);
    this.notificationSystem = null;
    this.onChangeCreditFormSubmit = this.onChangeCreditFormSubmit.bind(this);

    this.state = {
      ...this.defaultState(),
    };
  }

  componentDidMount() {
    this.getCredits();
  }

  async onChangeCreditFormSubmit(values, props) {
    const { mediaIds, callback } = this.props;
    if (!mediaIds.length) {
      return;
    }

    const isNewCredit = this.isNewOption(values.selectedCredit);
    const newFirmName = isNewCredit ? values.selectedCredit.label : false;
    const firmId = isNewCredit ? null : values.selectedCredit.value;

    const recaptchaToken = null; // Disabled recaptcha

    MediaResource.changeCredit(
      mediaIds,
      firmId,
      newFirmName,
      values.target,
      recaptchaToken,
    ).then((data) => {
      this.setState({ credit: prepareOption(data.firm.name, data.firm.djangoId) });
      props.setSubmitting(false);
      this.notificationSystem.addNotification({
        ...successNotificationSettings,
        message: `${data.changed} ${PHOTO_CREDIT_MESSAGE.SUCCESS}`,
      });
      segment.track(ANALYTICS_NAME.MEDIA_CREDITS_UPDATED, {
        count: mediaIds.length,
        mediaIds,
        target: values.target,
        isNewPhotographer: isNewCredit,
        photographerId: firmId,
      });
      setTimeout(this.props.close, 2000);
      callback(data);
    }).catch((error) => {
      props.setSubmitting(false);
      this.notificationSystem.addNotification({
        ...errorNotificationSettings,
        message: error.message,
      });
    });
  }

  getCredits = () => {
    const { mediaIds } = this.props;

    if (!mediaIds.length) {
      return false;
    }

    this.setState({ isLoading: true });

    const body = JSON.stringify({
      query: `query($mediaIds: String!) {
        allMediaItems(djangoId_In: $mediaIds) {
          edges {
            node {
              Credit {
                id: djangoId
                name
                slug
              }
            }
          }
        }
      }`,
      variables: {
        mediaIds: mediaIds.join(','),
      },
    });

    return APIClient('', true, body)
      .then((data) => {
        if ('errors' in data) {
          Raven.captureException(data.errors);
          throw new Error(data.errors);
        }
        this.setState({
          isLoading: false,
          credits: this.getOrderedCredits(data.allMediaItems),
          selectedCredit: this.populateSelectedCredit(data.allMediaItems),
        });
      });
  }

  getOrderedCredits = (mis) => {
    const misWithCredit = mis.filter(o => o.Credit);
    const misCounted = new Map(misWithCredit.map(o => [
      o.Credit.id,
      { ...(o.Credit || {}), count: 0 },
    ]));

    misWithCredit.forEach((mi) => {
      misCounted.get(mi.Credit.id).count += 1;
    });

    return Array.from(misCounted.values()).sort((a, b) => b.count - a.count);
  }

  populateSelectedCredit = (mis) => {
    let selectedCredit = {
      label: CREDIT_FIELD_PLACEHOLDER.NONE,
      value: null,
    };

    if (mis && mis.length) {
      const isSameCredit = mis.map(mi => (mi.Credit ? mi.Credit.id : null))
        .every((v, i, arr) => v === arr[0]);

      if (isSameCredit && mis[0].Credit !== null) { // Single photographer which is not null
        selectedCredit = prepareOption(mis[0].Credit.name, mis[0].Credit.id);
      } else if (!isSameCredit) { // Multiple photographers
        selectedCredit = {
          label: CREDIT_FIELD_PLACEHOLDER.MULTIPLE,
          value: null,
        };
      }
    }

    return selectedCredit;
  }

  defaultState = () => ({
    selectedCredit: {
      label: null,
      value: null,
    },
    credits: [],
    isLoading: false,
  });

  isNewOption = option => option.label === option.value;

  imagesWithPhotographersCount = () => {
    try {
      return this.state.credits.reduce((acc, v) => acc + v.count, 0);
    } catch (_) {
      return 0;
    }
  }

  render() {
    const { mediaIds } = this.props;
    const { isLoading, selectedCredit, credits } = this.state;
    const imagesWithPhotographersCount = this.imagesWithPhotographersCount();
    const imagesWithoutPhotographersCount = mediaIds.length - imagesWithPhotographersCount;

    if (!isLoading && !mediaIds.length) {
      return (<div className="pb-base text-center">No selected image.</div>);
    }

    return (
      <div className="flex-container flex-dir-column pb-base">
        {isLoading
          ? <div className="loading-spinner-m m-auto" />
          : (
            <Formik
              initialValues={{
                selectedCredit,
                target: imagesWithoutPhotographersCount === 0
                  ? MEDIA_CREDIT_TARGET.ALL
                  : MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY,
              }}
              validationSchema={validationSchema()}
              onSubmit={(values, props) => this.onChangeCreditFormSubmit(values, props)}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <div className="fs-s">
                  {/* Selected images information and photographers list */}
                  <div className="fs-base">
                    {credits.map((credit, i) => (
                      <Fragment key={credit.id}>
                        <a className="blue" href={`/firms/${credit.slug}`} target="_blank" rel="noreferrer">
                          {credit.name} ({credit.count})
                        </a>{i < credits.length - 1 && ', '}
                      </Fragment>
                    ))}
                    {imagesWithoutPhotographersCount > 0 && (
                    <span>
                      {credits.length > 0 && ', '}
                      Images without photographers (
                      {imagesWithoutPhotographersCount}
                      )
                    </span>
                    )}
                  </div>
                  <hr className="mt-base mb-base" />
                  {/* Photographer form */}
                  <form className="" onSubmit={handleSubmit}>
                    {/* Form typeahead */}
                    <h4 className="mb-xxs">Photographer</h4>
                    <FirmSelector
                      useFirmSelector
                      status={[PUBLISHED, DRAFT]}
                      value={values.selectedCredit}
                      filterOption={options => options}
                      resetValue={selectedCredit}
                      isCreatable
                      onChange={option => setFieldValue('selectedCredit', option, true)}
                      sortBy="projects_count"
                      name="selectedCredit"
                      disabled={isSubmitting}
                    />
                    {errors.selectedCredit && touched.selectedCredit && (
                    <small className="red">
                      {getIn(errors, 'selectedCredit.value')}
                    </small>
                    )}
                    {/* Target radios */}
                    {imagesWithPhotographersCount !== 0 && mediaIds.length > 1
                    && (
                    <div className="mt-s">
                      {imagesWithoutPhotographersCount !== 0 && (
                      <div className="mb-xs">
                        <Radio
                          type="radio"
                          id={MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY}
                          name="target"
                          className="mt-xxs mb-s"
                          value={MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY}
                          checked={values.target === MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY}
                          onChange={() => setFieldValue('target', MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY, true)}
                        />
                        <RadioLabel htmlFor={MEDIA_CREDIT_TARGET.NO_CREDIT_ONLY}>
                          <strong>Update ONLY ({imagesWithoutPhotographersCount}) image{imagesWithoutPhotographersCount > 1 ? 's' : ''}</strong>
                          <div className="medium-gray">Assign new photographer to ({imagesWithoutPhotographersCount}) image{imagesWithoutPhotographersCount > 1 ? 's' : ''} without credits.</div>
                        </RadioLabel>
                      </div>
                      )}
                      <div className="mb-xs">
                        <Radio
                          type="radio"
                          id={MEDIA_CREDIT_TARGET.ALL}
                          value={MEDIA_CREDIT_TARGET.ALL}
                          name="target"
                          className="mt-xxs mb-s"
                          checked={values.target === MEDIA_CREDIT_TARGET.ALL}
                          onChange={() => setFieldValue('target', MEDIA_CREDIT_TARGET.ALL, true)}
                        />
                        <RadioLabel htmlFor={MEDIA_CREDIT_TARGET.ALL}>
                          <strong>
                            Update ALL (
                            {mediaIds.length}
                            ) image
                            {mediaIds.length > 1 ? 's' : ''}
                          </strong>
                          <div className="medium-gray">
                            Override existing photographer for (
                            {imagesWithPhotographersCount}
                            ) image
                            {imagesWithPhotographersCount > 1 ? 's' : ''}
                            .
                          </div>
                        </RadioLabel>
                      </div>
                      <div>
                        {errors.target && touched.target && (
                        <small className="red">
                          {getIn(errors, 'target')}
                        </small>
                        )}
                      </div>
                    </div>
                    )}
                    {/* CTA */}
                    <div className="text-center mt-base">
                      <CTA
                        type="submit"
                        className="button primary ml-xs"
                        disabled={isSubmitting}
                      >
                        Update
                      </CTA>
                    </div>
                  </form>
                  {/* Notification */}
                  <NotificationSystem
                    ref={(ref) => { this.notificationSystem = ref; }}
                    noAnimation
                    style={notificationStyles}
                  />
                </div>
              )}
            </Formik>
          )}
      </div>
    );
  }
}

MediaCredit.propTypes = {
  mediaIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  close: PropTypes.func,
  callback: PropTypes.func,
};

MediaCredit.defaultProps = {
  mediaIds: [],
  close: () => { },
  callback: () => { },
};

export default MediaCredit;
