import React from 'react';
import PropTypes from 'prop-types';

import {
  isArrayEqual,
  hideGlobalHeader,
  showGlobalHeader,
} from '../../services/utils';
import SegmentClient from '../../services/SegmentClient';
import { UserResource } from '../../services/RESTClient';

export default class UniversalModalDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState(),
      isSuperUser: window.isSuperUser === 'True',
    };

    this.userResource = new UserResource();
    this.segment = new SegmentClient({
      userId: props.userId,
      ...props.extraAnalyticsInfo,
    });
    this.adHolderRef = React.createRef();
  }

  componentDidMount() {
    hideGlobalHeader();
    this.getUser();
    const { items } = this.props;

    if (items && items.length) {
      this.itemsDidChange();
    }

    if (window.UNIVERSAL_MODAL) {
      document.addEventListener('keyup', this.onUniversalModalKeyUp);
    }
    this.updateDocumentTitle();
  }

  componentDidUpdate(prevProps) {
    if (!this.isOpen && this.props.isOpen) {
      this.isOpen = true;
      this.pushHistory();
    }
    if (!isArrayEqual(prevProps.items, this.props.items)) {
      this.itemsDidChange();
    }
    this.updateDocumentTitle();
  }

  componentWillUnmount() {
    showGlobalHeader();
    document.removeEventListener('keyup', this.onUniversalModalKeyUp);
    this.isUnmounted = true;
    this.isOpen = false;
  }

  onUniversalModalKeyUp = (e) => {
    if (this.state.isLoading || !this.state.items || !this.state.items.length || e.target.tagName === 'INPUT') {
      return;
    }
    if (e.keyCode === 39) { // Right arrow
      this.next({ event: this.ANALYTICS_NAME.KEYBOARD_NEXT });
    } else if (e.keyCode === 37) { // Left arrow
      this.previous({ event: this.ANALYTICS_NAME.KEYBOARD_PREVIOUS });
    }
  };

  getUser() {
    this.setState({ isLoadingUser: true });
    this.userResource.me()
      .then((user) => {
        this.setState({ user: user.data });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isLoadingUser: false });
      });
  }

  getActiveItemDetails = () => {}

  updateDocumentTitle = () => {}

  itemsDidChange = () => {
    const { items } = this.props;
    if (!items || !items.length) {
      return;
    }

    let activeItemIndex = items.findIndex(item => item.isActive);
    if (activeItemIndex === -1) {
      activeItemIndex = 0;
      items[activeItemIndex].isActive = true;
    }
    this.setState({ items, activeItemIndex }, () => {
      this.getActiveItemDetails();
    });
  }

  triggerGenericOpenEvent = () => {
    this.segment.track(this.ANALYTICS_NAME.MODAL_OPENED);
  }

  refreshAd = () => {
    if (this.adHolderRef && this.adHolderRef.current && this.adHolderRef.current.refresh) {
      this.adHolderRef.current.refresh();
    }
  }

  moveActiveItemIndex = (step = 0) => {
    const { items, activeItemIndex } = this.state;
    if (!items || !items.length || !step) {
      return;
    }
    const UpdatedItems = [...items];

    let newActiveItemIndex = (activeItemIndex + step) % UpdatedItems.length;
    newActiveItemIndex = newActiveItemIndex < 0 ? UpdatedItems.length - 1 : newActiveItemIndex;

    UpdatedItems[activeItemIndex].isActive = false;
    UpdatedItems[newActiveItemIndex].isActive = true;

    this.setState({ activeItemIndex: newActiveItemIndex, items: UpdatedItems }, () => {
      this.pushHistory();
      this.triggerGenericOpenEvent();
      this.getActiveItemDetails();
      this.refreshAd();
    });
  }

  next = ({ event }) => {
    this.moveActiveItemIndex(1);
    if (event) {
      this.segment.track(event, {}, { Intercom: false });
    }
  }

  previous = ({ event }) => {
    this.moveActiveItemIndex(-1);
    if (event) {
      this.segment.track(event, {}, { Intercom: false });
    }
  }

  openRequireRegister = ({
    permissionType,
    requireRegisterNext,
    destinationTitle,
    destinationURL,
  }) => this.setState({
    openRequireRegister: true,
    ...(permissionType ? { permissionType } : {}),
    ...(requireRegisterNext ? { requireRegisterNext } : {}),
    ...(destinationTitle ? { destinationTitle } : {}),
    ...(destinationURL ? { destinationURL } : {}),
  });

  closeRequireRegister = () => this.setState({
    openRequireRegister: false,
    requireRegisterNext: null,
    destinationTitle: null,
    destinationURL: null,
  }, hideGlobalHeader);

  defaultState = () => ({
    isSuperUser: false,
    user: null,
    // Media Item Attribution ids
    items: [],
    activeItemIndex: -1,
    isLoading: false,
    isLoadingUser: false,
    // Content Gate Params
    openRequireRegister: false,
    permissionType: null,
    requireRegisterNext: null,
    destinationTitle: null,
    destinationURL: null,
  });

  pushHistory = this.props.pushHistory;

  close = this.props.close;

  callSource = this.props.callSource;
}

UniversalModalDetails.propTypes = {
  userId: PropTypes.number,
  extraAnalyticsInfo: PropTypes.shape({}),
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  pushHistory: PropTypes.func.isRequired,
  callSource: PropTypes.string,
};

UniversalModalDetails.defaultProps = {
  userId: null,
  extraAnalyticsInfo: {},
  isOpen: false,
  close: () => {},
  callSource: '',
};
