import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ANALYTICS_NAME,
  BASE_URL,
} from './Constants';
import {
  Container,
  Loader,
  MetaDesktop,
  MetaMobile,
  Close,
  ImageWrapper,
  Caption,
  Navigation,
  ImageActionsContainer,
  ImageContainer,
  RightRail,
  Previous,
  Next,
  Image,
  Title,
} from '../universal_modal/SharedComponents';
import { CONTACT_MODAL_HASH } from '../brand_detail/Constants';
import APIClient, {
  defaultLogoImageUrl,
  iterUnpack,
} from '../../services/APIClient';
import ImageActions from '../universal_modal/ImageActions';
import FailedPlaceholder from '../universal_modal/FailedPlaceholder';
import UniversalModalDetails from '../universal_modal/UniversalModalDetails';
import DetailsMediaCredit from '../universal_modal/DetailsMediaCredit';
import ReadMoreText from '../ReadMoreText';
import AvatarWithSideInfo from '../AvatarWithSideInfo';
import { arrayToReadableString } from '../../services/utils';
import RequireRegisterPopup, { PERMISSION_TYPES } from '../RequireRegisterPopup';

const InfoSection = styled.div.attrs(props => ({
  className: `mb-s pb-s border-global-separator ${props.show ? '' : 'hide'}`,
}))`
  border-bottom: 1px solid;
`;

export default class BrandPhotoDetails extends UniversalModalDetails {
  constructor(props) {
    super(props);

    this.brandProfileUrl = `/${BASE_URL}/${this.props.brandSlug}`;
    this.ANALYTICS_NAME = ANALYTICS_NAME;
    this.state = {
      ...this.state,
      ...this.defaultState(),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getBrandInfo();
  }

  updateMediaCredit = ({ firm: { slug: newFirmSlug, name: newFirmName } }) => {
    if (newFirmSlug && newFirmName) {
      const { items, activeItemIndex } = this.state;
      const itemsClone = [...items];
      itemsClone[activeItemIndex] = {
        ...(itemsClone[activeItemIndex] || {}),
        creditName: newFirmName,
        creditSlug: newFirmSlug,
      };
      this.setState({ items: itemsClone });
    }
  };

  getBrandInfo() {
    const query = `{
      allBrands(slug: "${this.props.brandSlug}") {
        edges {
          node {
            name
            id: djangoId
            productsCount
            isBrandAdmin
            logo: media(last:1, type: "logo") {
              edges {
                node {
                  djangoId
                  mediaItem {
                    url
                  }
                }
              }
            }
            productCategories: pseudotiers {
              edges {
                node {
                  pseudotier {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

    APIClient(query, true).then((data) => {
      const brand = iterUnpack(data).allBrands[0];
      brand.productCategories = brand.productCategories && brand.productCategories.map(category => (
        category.pseudotier
      ));
      this.setState({ brand });
    });
  }

  getActiveItemDetails = () => {
    const { items, activeItemIndex } = this.state;

    if (!items || !items.length || !items[activeItemIndex] || items[activeItemIndex].isLoaded) {
      return;
    }
    this.setState({ isLoading: true });

    const query = `{
      allImages(
        first: 1,
        mediaItemAttributionId: ${items[activeItemIndex].id},
        mediaType: "brand_photo",
      ) {
        images: edges {
          node {
            globalId
            url
            mediaAttributionId
            title
            description
            tags
            creditName
            creditSlug
            projectPlaceholderName
            projectPlaceholderTypeTag
            projectPlaceholderLocation
            brandPhotoFirmName
            brandPhotoFirmSlug
            mediaId
          }
        }
      }
    }`;
    APIClient(query, true).then((data) => {
      if (!this.isUnmounted) {
        const updatedItems = [...items];
        const { images } = data.allImages;
        updatedItems[activeItemIndex] = {
          ...items[activeItemIndex],
          ...images.length ? images[0].node : {},
          isLoaded: Boolean(images.length),
        };

        this.setState({ items: updatedItems, isLoading: false });
      }
    }).catch(() => {});
  }

  openBrandContactModal() {
    const contactBtn = document.getElementById('contact-modal');
    if (contactBtn) {
      this.props.close(true);
      document.querySelector(`a[href='${CONTACT_MODAL_HASH}']`).click();
    } else {
      window.location.href = `/${BASE_URL}/${this.props.brandSlug}/${CONTACT_MODAL_HASH}`;
    }
  }

  getDocumentTitle = () => {
    const { items, activeItemIndex, brand: { name } } = this.state;
    const photo = items.length && items[activeItemIndex] ? items[activeItemIndex] : null;
    let docTitle = null;

    if (photo && photo.title && name) {
      const title = `${photo.title.substr(0, 12)}${photo.title.length > 12 ? '...' : ''}`;
      docTitle = `${title} - ${name} - Architizer`;
    } else if (name) {
      docTitle = `Photos for ${name}`;
    }
    return docTitle;
  };

  updateDocumentTitle = () => {
    const title = this.getDocumentTitle();
    if (title) {
      document.title = title;
    }
  };

  onBrandNameClicked(event) {
    if (this.props.callSource === window.UNIVERSAL_MODAL.TYPE.BRAND_PHOTO) {
      event.preventDefault();
      this.props.close(true);
    }
  }

  pushHistory = () => {
    const { items, activeItemIndex } = this.state;
    this.props.pushHistory(`/${BASE_URL}/${this.props.brandSlug}/photos/${items[activeItemIndex].id}/`);
  };

  handleContactClick = () => {
    if (this.userResource.canContactBrand()) {
      this.openBrandContactModal();
    } else {
      this.openRequireRegister({
        permissionType: PERMISSION_TYPES.CONTACT,
        destinationURL: `${window.location.href}#contact`,
      });
    }
  }

  defaultState = () => ({
    brand: {
      name: '',
      logo: [],
    },
  });

  render() {
    const {
      isLoading, items, activeItemIndex, brand, permissionType,
    } = this.state;

    const isValidItem = items && items.length && typeof activeItemIndex === 'number' && items[activeItemIndex];
    const item = isValidItem ? items[activeItemIndex] : {};
    const meta = isValidItem ? `${activeItemIndex + 1} / ${items.length}` : null;

    const {
      mediaAttributionId = null,
      url = null,
      title = null,
      description = null,
      brandPhotoFirmName = null,
      brandPhotoFirmSlug = null,
      projectPlaceholderName = null,
      projectPlaceholderLocation = null,
      projectPlaceholderTypeTag = null,
      tags = [],
      creditName = null,
      isLoaded = false,
    } = item;

    const {
      id = null,
      name = null,
      slug = null,
      isBrandAdmin = false,
      productCategories = [],
      logo = [],
      productsCount = 0,

    } = brand;

    return (
      <Container>
        {/* Meta */}
        <MetaDesktop>{meta}</MetaDesktop>
        {/* Close Button */}
        <Close href={null} onClick={() => this.props.close(true)} />

        {/* Left Area - Image */}
        <ImageContainer>
          {/* Image */}
          <ImageWrapper>
            {isLoading && <Loader />}
            {!isLoading && !isLoaded && (
            <FailedPlaceholder
              back={{ onClick: () => this.props.close(true) }}
            />
            )}
            {isLoaded && (
            <Image
              url={url}
              title={title}
              company={name}
              credit={creditName}
            />
            )}
          </ImageWrapper>
          {/* Caption */}
          <Caption top>
            <MetaMobile>{meta}</MetaMobile>
            <Title
              name={title}
              companyNames={[name]}
              companySlugs={[slug]}
              companyUrlRoot="brands"
              ANALYTICS_NAME={ANALYTICS_NAME}
            />
            <DetailsMediaCredit
              media={item}
              isEditable={Boolean(this.userResource.isBrandAdmin(id))}
              callback={this.updateMediaCredit}
            />
          </Caption>
          {/* Navigation */}
          <Navigation>
            <Previous onClick={() => this.previous({ event: ANALYTICS_NAME.ON_IMAGE_PREVIOUS })} />
            <Next onClick={() => this.next({ event: ANALYTICS_NAME.ON_IMAGE_NEXT })} />
          </Navigation>
          {/* Image Actions */}
          <ImageActionsContainer>
            <ImageActions
              item={item}
              callSource={this.props.callSource}
              collectable={false}
              ANALYTICS_NAME={ANALYTICS_NAME}
              segment={this.segment}
              isSuperUser={this.state.isSuperUser}
              edit={isBrandAdmin ? {
                href: `/brands/${this.props.brandSlug}/photos/edit/${mediaAttributionId}/`,
              } : null}
              openRequireRegister={this.openRequireRegister}
            />
          </ImageActionsContainer>
        </ImageContainer>

        {/* Right Area - Rail */}
        <RightRail isPadded scrollable>
          {/* Brand Avatar */}
          <AvatarWithSideInfo
            className="mb-base"
            nameOnClick={event => this.onBrandNameClicked(event)}
            nameHref={this.brandProfileUrl}
            name={name}
            nameSize="m"
            caption={arrayToReadableString(productCategories, { textField: 'name' })}
            url={logo.length ? logo[0].mediaItem.url : ''}
            placeholder={defaultLogoImageUrl}
            height={65}
            width={65}
            description={productsCount ? `${productsCount} Products` : null}
            descriptionColor="gray-300"
            descriptionSize="s"
          />
          {/* Contact Button */}
          <button
            onClick={this.handleContactClick}
            className="button white fw-medium mb-base expanded"
            type="button"
          >
            <span className="material-icons fs-s mr-s">chat_bubble</span>
            {' '}
            Contact
          </button>
          {/* Photo Title */}
          <InfoSection show={Boolean(title) || Boolean(description)}>
            <h4 className="fs-base fw-bold mb-xs">{title}</h4>
            {/* Photo Description */}
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <ReadMoreText className="fs-base" text={description || ''} />
            </div>
          </InfoSection>
          {/* Photo Firm */}
          <InfoSection show={Boolean(brandPhotoFirmName)}>
            <h4 className="fs-base fw-bold mb-xs">Firm</h4>
            <a
              target="_blank"
              rel="noreferrer"
              href={brandPhotoFirmSlug ? `/firms/${brandPhotoFirmSlug}` : null}
              className="fs-base gray-300"
            >
              {brandPhotoFirmName}
            </a>
          </InfoSection>
          {/* Photo Project */}
          <InfoSection
            show={
              Boolean(projectPlaceholderName)
              || Boolean(projectPlaceholderLocation)
              || Boolean(projectPlaceholderTypeTag)
            }
          >
            <h4 className="fs-base fw-bold mb-xs">Project</h4>
            <p className="fs-base m-0 gray-300">{projectPlaceholderName}</p>
            <p className="fs-base m-0 gray-300">{projectPlaceholderLocation}</p>
            <p className="fs-base m-0 gray-300">{projectPlaceholderTypeTag}</p>
          </InfoSection>
          {/* Photo Tags */}
          <InfoSection show={Boolean(tags.length)}>
            {tags.map(tag => (
              <span key={tag} className="mr-base inline-block fs-base gray-300 underline">{tag}</span>
            ))}
          </InfoSection>
        </RightRail>
        {/* Content Gates */}
        {this.state.openRequireRegister && (
        <RequireRegisterPopup
          joinCompany={[
            PERMISSION_TYPES.FIND_SIMILAR,
          ].includes(permissionType)}
          submitProfessional={[
            PERMISSION_TYPES.CONTACT,
            PERMISSION_TYPES.COLLECT,
            PERMISSION_TYPES.SAVE_IMAGE,
          ].includes(permissionType)}
          permissionType={permissionType}
          permissionDestinationTitle={this.state.destinationTitle}
          permissionDestinationUrl={this.state.destinationURL}
          nextParam={this.state.requireRegisterNext}
          onClose={this.closeRequireRegister}
        />
        )}
      </Container>
    );
  }
}

BrandPhotoDetails.propTypes = {
  brandSlug: PropTypes.string.isRequired,
  brand: PropTypes.shape({}),
};

BrandPhotoDetails.defaultProps = {
};
