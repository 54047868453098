import * as yup from 'yup';
import {
  PHOTO_CREDIT_VALIDATION,
  MEDIA_CREDIT_TARGET,
} from './Constants';

const validationSchema = () => yup.object().shape({
  selectedCredit: yup.object()
    .shape({
      value: yup
        .string()
        .typeError(PHOTO_CREDIT_VALIDATION.INVALID_FIRM)
        .min(1)
        .max(100),
    })
    .required(),
  target: yup
    .string()
    .oneOf(Object.values(MEDIA_CREDIT_TARGET))
    .required(PHOTO_CREDIT_VALIDATION.REQUIRED),
});

export default validationSchema;
