import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { UserResource } from '../services/RESTClient';
import SegmentClient from '../services/SegmentClient';
import { MEDIA_QUERIES } from '../services/constants';

const userResource = new UserResource();

export const PERMISSION_TYPES = {
  COLLECT: 'Add To Collection',
  CONTACT: 'Contact',
  SAVE_IMAGE: 'Save Image',
  FIND_SIMILAR: 'Find Similar',
};

const RequireRegisterWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: ${MEDIA_QUERIES.small}) and (orientation: landscape) {
    align-items: flex-start;
  }
  overflow: auto;
`;

const Separater = styled.div`
  & {
    width: 100%;
    max-width: 19.35rem;
    position: relative;
    text-align:center;
    span {
      background: white;
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 20px);
      height: 1px;
      background: rgba(65,85,100,.3);
      left: 10px;
      top: 50%;
    }
  }
`;

const CloseButton = styled.button`
    position: absolute;
    right: 8px;
    top: 8px;
    width: auto;
`;

const RequireRegistrationStyle = styled.div`
  background: #fff;
  width: calc(100vw - 1.6rem);
  max-width: ${props => (props.joinToContinue ? '23.75rem' : '18.75rem')};
  border-radius: 0.3125rem;
  margin: 1.25rem 0 !important;
  position: relative;
  
  .registration-required-message {
    width: 100%; // IE fix
  }
  
  ${props => props.joinToContinue && `{
    .message-text {
      @media (max-width: ${MEDIA_QUERIES.small}) {
        font-size: 1.25rem;
      }
    }
  }`}
  
  a {
    width: calc(100% - 3.125rem) !important;
    max-width: 14.35rem;
    
    &:not(:last-child) {
      margin-bottom: 1.5625rem !important;
    }
  }
`;

const MESSAGE_TYPES = {
  DEFAULT: 'DEFAULT',
  JOIN_COMPANY: 'JOIN_COMPANY',
  SUBMIT_PROFESSIONAL: 'SUBMIT_PROFESSIONAL',
};

const segment = new SegmentClient();

function removeOrAddClasses(elems, className, add) {
  const method = add ? 'add' : 'remove';
  for (let i = 0; i < elems.length; i += 1) {
    elems[i].classList[method](className);
  }
}

class RequireRegisterPopup extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.trackPromises = [];
    this.trackPromises.push(new Promise((resolve) => {
      this.popupOpenedPromiseResolver = resolve;
    }));
    // if this property exists that means we need to wait sending the
    // track event until this new Promise resolved from the outer component
    // this was done because some page titles doesn't update until a request done
    if (props.sendTrackEventResolver) {
      this.trackPromises.push(new Promise(resolve => props.sendTrackEventResolver(resolve)));
    }
  }

  componentDidMount() {
    const {
      permissionType,
      permissionDestinationUrl,
      permissionDestinationTitle,
      nextParam,
    } = this.props;

    // This promise must be resolved before track
    this.trackPromises.push(
      new Promise((resolve) => {
        userResource.me()
          .then(user => this.setState({ user: user.data }))
          .catch(() => {})
          .finally(() => {
            this.setState({ isLoadingUser: false });
            resolve();
          });
      }),
    );

    const isNavigationEvent = !permissionType;
    Promise.all(this.trackPromises).then((docTitle) => {
      const documentTitle = docTitle[0] || document.title;

      // eslint-disable-next-line no-restricted-globals
      const prevPage = (history.state && history.state.state && history.state.state.previousPage) || { url: '', title: '' };

      segment.identify({
        traits: {
          trigger_type: isNavigationEvent ? 'Navigation' : permissionType,
          destination_page_title: permissionDestinationTitle || (isNavigationEvent ? documentTitle : ''),
        },
      });

      segment.track('triggered_signup', {
        trigger_type: isNavigationEvent ? 'Navigation' : permissionType,
        origin_page_title: isNavigationEvent ? prevPage.title : documentTitle,
        origin_url: isNavigationEvent ? prevPage.url : window.location.href,
        destination_page_title: permissionDestinationTitle || (isNavigationEvent ? documentTitle : ''),
        destination_page_url: isNavigationEvent
          ? window.location.href : (permissionDestinationUrl || nextParam),
      });
    });
  }

  componentWillUnmount() {
    removeOrAddClasses(document.getElementsByTagName('body'), 'of-y-hidden');
    const openedModals = document.getElementsByClassName('popup-modal-overlay');
    removeOrAddClasses(openedModals, 'decrease-index');
  }

  defaultState = () => ({
    user: null,
    isLoadingUser: true,
  });

  render = () => {
    const { isLoadingUser } = this.state;
    const {
      submitProfessional,
      joinCompany,
      waitForApprove,
      useRestrictionCookie,
      onClose,
      nextParam,
      permissionType,
    } = this.props;
    const showPopup = !isLoadingUser && userResource.requirePermissionGate({
      submitProfessional,
      joinCompany,
      waitForApprove,
      useRestrictionCookie,
    });
    const nextParamValue = encodeURIComponent(nextParam || window.location.pathname);

    const body = document.getElementsByTagName('body');
    const openedModals = document.getElementsByClassName('popup-modal-overlay');
    if (showPopup) {
      removeOrAddClasses(body, 'of-y-hidden', true);
      removeOrAddClasses(openedModals, 'decrease-index', true);
      this.popupOpenedPromiseResolver();
    } else {
      removeOrAddClasses(body, 'of-y-hidden');
      removeOrAddClasses(openedModals, 'decrease-index');
    }

    let messageType = MESSAGE_TYPES.DEFAULT;
    if (joinCompany) {
      messageType = MESSAGE_TYPES.JOIN_COMPANY;
    } else if (submitProfessional) {
      messageType = MESSAGE_TYPES.SUBMIT_PROFESSIONAL;
    }

    const isNavigationEvent = !permissionType;

    return (
      showPopup && (
        <RequireRegisterWrapper>
          <RequireRegistrationStyle
            joinToContinue={isNavigationEvent && !userResource.isAuthenticated()}
            className="align-middle pb-base flex-container flex-dir-column pl-s pr-s"
          >
            {onClose && (
              <CloseButton
                className="close-button light-gray"
                aria-label="Close modal"
                onClick={onClose}
                type="button"
              >
                <span
                  className="material-icons"
                  aria-hidden="true"
                >
                  close
                </span>
              </CloseButton>
            )}
            {(!userResource.isAuthenticated())
            && (
            <>
              <div className="text-center registration-required-message mb-base mt-l">
                {messageType === MESSAGE_TYPES.DEFAULT
                  && <h4 className="mb-s message-text fw-bold">Keep Exploring Architizer by Creating a Free Account or Logging in.</h4>}
                {messageType === MESSAGE_TYPES.JOIN_COMPANY
                  && (
                  <span className="fs-base">
                    This feature is for industry professionals.&nbsp;
                    To unlock it, signup and then join or add your company.
                  </span>
                  )}
                {messageType === MESSAGE_TYPES.SUBMIT_PROFESSIONAL
                  && (
                  <span className="fs-base">
                    To unlock this feature,&nbsp;
                    signup and then submit your professional details.
                  </span>
                  )}
              </div>
              {messageType !== MESSAGE_TYPES.DEFAULT && <p className="text-center">Membership is Free.</p>}
              <a
                href={`/login/linkedin-oauth2/?next=${nextParamValue}`}
                className="expanded pill button white"
                style={{ backgroundColor: '#287cbd' }}
              >
                <span />
                <strong>LinkedIn</strong>
              </a>
              <a
                href={`/login/facebook/?next=${nextParamValue}`}
                className="expanded pill button white"
                style={{ backgroundColor: '#3c5999' }}
              >
                <span />
                <strong>Facebook</strong>
              </a>
              <a
                href={`/login/google-oauth2/?next=${nextParamValue}`}
                className="expanded pill button white"
                style={{ backgroundColor: '#d14836' }}
              >
                <span />
                <strong>Google</strong>
              </a>
              <Separater className="mb-base"><span className="pl-base pr-base">or</span></Separater>
              <a
                onClick={() => segment.track('selected_email_signup_method')}
                href={`/register?next=${nextParamValue}`}
                className="expanded pill button white"
              >
                <span />
                <strong>Email</strong>
              </a>
              <a className="fs-s text-center" href={`/login?next=${nextParamValue}`}>Already a Member? Sign in.</a>
            </>
            )}
            {userResource.notVerified() && (
              <>
                <p className="text-center registration-required-message mb-base mt-l">
                  {messageType === MESSAGE_TYPES.DEFAULT
                    && <span>To access this page, you need to verify your email.</span>}
                  {messageType === MESSAGE_TYPES.JOIN_COMPANY
                    && (
                    <span className="fs-base">
                      This feature is for industry professionals. To unlock it,&nbsp;
                      verify your email and then join or add your company.
                    </span>
                    )}
                  {messageType === MESSAGE_TYPES.SUBMIT_PROFESSIONAL
                    && (
                    <span className="fs-base">
                      To unlock this feature,&nbsp;
                      verify your email and then submit your professional details.
                    </span>
                    )}
                </p>
                <a href={`/email-authentication/?resend&next=${nextParamValue}`} className="expanded pill button white">
                  <span />
                  <strong>Verify your email</strong>
                </a>
              </>
            )}
            {userResource.isGeneric() && (
              <>
                <p className="text-center registration-required-message mb-base mt-l">
                  {messageType === MESSAGE_TYPES.JOIN_COMPANY
                    && (
                    <span className="fs-base">
                      This feature is for industry professionals.&nbsp;
                      To unlock it, join or add your company.
                    </span>
                    )}
                  {messageType === MESSAGE_TYPES.SUBMIT_PROFESSIONAL
                    && <span className="fs-base">To unlock this feature, submit your professional details.</span>}
                </p>
                <a href={`/users/companies/join/?next=${nextParamValue}`} className="expanded pill button white">
                  <span />
                  <strong>Join a Company</strong>
                </a>
              </>
            )}
            {userResource.isNonProfessional() && (
              <>
                <p className="text-center registration-required-message mb-base mt-l">
                  <span className="fs-base">
                    This feature is for industry professionals.&nbsp;
                    To unlock it, join or add your company.
                  </span>
                </p>
                <a href={`/users/companies/join/?next=${nextParamValue}`} className="expanded pill button white">
                  <span />
                  <strong>Join a Company</strong>
                </a>
              </>
            )}
            {userResource.isPendingProfessional() && (
              <>
                <p className="text-center registration-required-message mb-base mt-l">
                  <span className="fs-base">
                    This feature is only available to approved professionals.&nbsp;
                    Your request is currently&nbsp;
                    <a href="/welcome/exist-company">pending approval</a>
                    .
                  </span>
                </p>
              </>
            )}
          </RequireRegistrationStyle>
        </RequireRegisterWrapper>
      )
    );
  }
}

RequireRegisterPopup.propTypes = {
  submitProfessional: PropTypes.bool,
  joinCompany: PropTypes.bool,
  waitForApprove: PropTypes.bool,
  permissionType: PropTypes.string,
  useRestrictionCookie: PropTypes.bool,
  onClose: PropTypes.func,
  sendTrackEventResolver: PropTypes.func,
  nextParam: PropTypes.string,
  permissionDestinationUrl: PropTypes.string,
  permissionDestinationTitle: PropTypes.string,
};

RequireRegisterPopup.defaultProps = {
  submitProfessional: false,
  joinCompany: false,
  waitForApprove: false,
  useRestrictionCookie: false,
  permissionType: null,
  sendTrackEventResolver: null,
  onClose: null,
  nextParam: null,
  permissionDestinationUrl: null,
  permissionDestinationTitle: null,
};

export default RequireRegisterPopup;
