import React from 'react';
import PropTypes from 'prop-types';

export default class ExpirationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: props.isInitiallyVisible ? props.duration : 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.trigger !== this.props.trigger) {
      this.setState({ duration: this.props.duration }, () => {
        this.clearThisInterval();

        this.interval = setInterval(() => {
          this.setState({ duration: this.state.duration - 10 }, () => {
            if (this.state.duration <= 0) {
              this.clearThisInterval();
            }
          });
        }, 10);
      });
    }
  }

  componentWillUnmount() {
    this.clearThisInterval();
  }

  clearThisInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <div
        style={{
          overflow: 'hidden',
          height: 'auto',
          maxHeight: this.state.duration > 0 ? '999px' : 0,
          transition: 'max-height .75s ease-in-out',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

ExpirationContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isInitiallyVisible: PropTypes.oneOf([true, false]),
  duration: PropTypes.number,
  trigger: PropTypes.bool,
};

ExpirationContainer.defaultProps = {
  isInitiallyVisible: false,
  duration: 0,
  trigger: false,
};
