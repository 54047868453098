export const BASE_URL = 'idea';

export const ANALYTICS_NAME = {
  MODAL_FIRM_CLICKED: 'image search modal - firm clicked',
  MODAL_PROJECT_CLICKED: 'image search modal - project clicked',
  MODAL_CONTACT_PROJECT_CLICKED: 'image search modal - project clicked',
  MODAL_CONTACT_FIRM_CLICKED: 'image search modal - contact firm',
  MODAL_VIEW_ALL_FIRM_IMAGES: 'image search modal - view all firm images',
  MODAL_ALL_PROJECT_TYPE: 'image search modal - view all project type',
  MODAL_VIEW_ALL_LOCATION: 'image search modal - view all location',
  MODAL_VIEW_ALL_SEARCH_TERM: 'image search modal - view all search term',
  RECOMMENDED_PRODUCT_DISPLAYED: 'brand_exposure_events',
  SIMILAR_PRODUCTS_CLICKED: 'image search modal - similar products clicked',
  SIMILAR_PRODUCTS_BRAND_CLICKED: 'image search modal - similar products brand clicked',
  SIMILAR_IMAGE_CLICKED: 'image search modal - similar image clicked',
  MODAL_VIEW_ALL_PRODUCTS_CLICKED: 'image search modal - view all products',
  MODAL_VIEW_ALL_IMAGES_CLICKED: 'image search modal - view all images',
  MODAL_OPENED: 'image search modal - opened',
  SHARE_PINTEREST: 'image search modal - share Pinterest',
  SHARE_WHATSAPP: 'image search modal - share Whatsapp',
  SHARE_FACEBOOK: 'image search modal - share Facebook',
  SHARE_LINKEDIN: 'image search modal - share LinkedIn',
  SHARE_TWITTER: 'image search modal - share Twitter',
  SHARE_LINK: 'image search modal - share Copy Link',
  SHARE_EMAIL: 'image search modal - share Email',
  CORNER_BUTTON_NEXT: 'image search modal - next navigation',
  CORNER_BUTTON_PREVIOUS: 'image search modal - previous navigation',
  ON_IMAGE_NEXT: 'image search modal - next navigation on image',
  ON_IMAGE_PREVIOUS: 'image search modal - previous navigation on image',
  KEYBOARD_NEXT: 'image search modal - next navigation from keyboard',
  KEYBOARD_PREVIOUS: 'image search modal - next navigation from keyboard',
};

export const PROJECT_STATUS = {
  EMPTY: '',
  PUBLISHED: 'PUBLISHED',
};
