import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  prepareImgixParams,
  placeholder43Url,
} from '../services/APIClient';

const WEIGHTS = {
  normal: 'normal',
  bold: 'bold',
};

const SIZES = {
  xs: 'xs',
  s: 's',
  base: 'base',
  m: 'm',
  l: 'l',
};

const AvatarWithSideInfo = ({
  className,
  url,
  avatarHref,
  width,
  height,
  placeholder,
  name,
  nameWeight,
  nameSize,
  nameHref,
  nameOnClick,
  nameColor,
  caption,
  description,
  descriptionWeight,
  descriptionColor,
  descriptionSize,
  descriptionHref,
  descriptionOnClick,
}) => {
  const imageUrl = width && height && url
    ? prepareImgixParams(url, { w: width, h: height, auto: 'format,compress' })
    : placeholder;
  const nameClassName = `cell ellipsis fs-${nameSize} fw-${nameWeight} ${nameColor}`;
  const descriptionClassName = `cell ellipsis m-0 fs-${descriptionSize} fw-${descriptionWeight} ${descriptionColor}`;
  const avatar = <img alt={name} src={imageUrl} style={{ width: `${width}px`, height: `${height}px` }} />;
  return (
    <div
      className={classnames(
        'grid-x',
        { [className]: className },
      )}
    >
      <div className="cell shrink align-self-middle">
        {avatarHref ? <a href={avatarHref} target="_blank" rel="noreferrer">{avatar}</a> : avatar}
      </div>
      <div className="cell auto grid-x align-middle pl-xs text-left" style={{ alignContent: 'center' }}>
        {nameHref || nameOnClick
          ? (
            <a
              href={nameHref}
              onClick={nameOnClick}
              className={nameClassName}
              target="_blank"
              rel="noreferrer"
              title={name}
            >
              {name}
            </a>
          )
          : <h4 className={nameClassName} title={name}>{name}</h4>}
        {caption && <h6 className="mb-0" title={caption}>{caption}</h6>}
        {descriptionHref || descriptionOnClick
          ? (
            <a
              href={descriptionHref}
              onClick={descriptionOnClick}
              className={descriptionClassName}
              target="_blank"
              rel="noreferrer"
              title={description}
            >
              {description}
            </a>
          )
          : <h6 className={descriptionClassName} title={description}>{description}</h6>}
      </div>
    </div>
  );
};

AvatarWithSideInfo.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  avatarHref: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  nameWeight: PropTypes.oneOf(Object.values(WEIGHTS)),
  nameSize: PropTypes.oneOf(Object.values(SIZES)),
  nameHref: PropTypes.string,
  nameOnClick: PropTypes.func,
  nameColor: PropTypes.string,
  caption: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  descriptionWeight: PropTypes.oneOf(Object.values(WEIGHTS)),
  descriptionSize: PropTypes.oneOf(Object.values(SIZES)),
  descriptionHref: PropTypes.string,
  descriptionOnClick: PropTypes.func,
};

AvatarWithSideInfo.defaultProps = {
  className: '',
  url: '',
  avatarHref: '',
  width: 50,
  height: 50,
  placeholder: placeholder43Url,
  name: '',
  nameWeight: WEIGHTS.normal,
  nameSize: SIZES.base,
  nameHref: null,
  nameOnClick: null,
  nameColor: 'black',
  caption: '',
  description: '',
  descriptionColor: 'black',
  descriptionWeight: WEIGHTS.normal,
  descriptionSize: SIZES.s,
  descriptionHref: null,
  descriptionOnClick: null,
};

export default AvatarWithSideInfo;
