import invert from 'lodash/invert';
import { prepareOption } from '../../services/utils';

export const BASE_URL = 'image-search';

export const BASE_URL_PATTERN = `^/${BASE_URL}/.+$`;

export const POPULAR_IMAGES = {
  text: 'Popular Images',
  slug: 'popular-images',
};

export const DETAIL_CATEGORY = 'idea';

export const URL_TYPE = {
  DETAIL: DETAIL_CATEGORY,
  POPULAR_IMAGES: POPULAR_IMAGES.slug,
  CLASSIFICATION: 'classification',
};

export const ANALYTICS_NAME = {
  SEARCH_TERM_CLICK: 'image search homepage - search term clicked',
  SEARCH_SUBMITTED: 'image search - search submitted',
  RESULT_CLICKED: 'image search - result clicked',
};

const CATEGORY_TEXT = {
  EXTERIOR_SPACE: 'Exterior Spaces',
  INTERIOR_SPACE: 'Interior Spaces',
  FEATURE: 'Building Features',
  PRODUCT: 'Building Products',
  ARCHITECTURAL_DRAWING: 'Architectural Drawings',
};
const CATEGORY_SLUG = () => {
  const slugs = {};
  Object.keys(CATEGORY_TEXT).forEach((key) => {
    slugs[key] = CATEGORY_TEXT[key].toLowerCase().split(/\s+/).join('-');
  });
  return slugs;
};
export const CATEGORIES = {
  TEXT: CATEGORY_TEXT,
  SLUG: CATEGORY_SLUG(),
  INVERTED_SLUG: invert(CATEGORY_SLUG()),
};

export const OPERATION = {
  LOAD_MORE: 'LOAD_MORE',
  LOAD_NEW: 'LOAD_NEW',
};

export const filtersMap = {
  type: 'selectedTypes',
  material: 'selectedMaterials',
  style: 'selectedStyles',
  environment: 'selectedEnvironments',
  location: 'selectedLocations',
  firm: 'selectedFirms',
};
export const SORTING_KEY = 'sort';

export const SEARCH_TERM = 'searchTerm';

export const emptyFilterMessage = 'No related results';

export const SORTINGS = [
  prepareOption('Featured', 'featured'), // <== default
  prepareOption('Most Recent', 'most-recent'),
  prepareOption('A+Award Winners', 'a-plus-award'),
];

export const IMAGE_SEARCH_URL = `/${BASE_URL}/${POPULAR_IMAGES.slug}/`;

export const IMAGE_SEARCH_PRODUCT_URL = `/${BASE_URL}/${CATEGORIES.SLUG.PRODUCT}/`;
