export const ANALYTICS_NAME = {
  PAGE_OPEN: 'Brand Edit Photos',
  CORNER_BUTTON_PREVIOUS: 'brand photo modal - previous navigation',
  CORNER_BUTTON_NEXT: 'brand photo modal - next navigation',
  ON_IMAGE_PREVIOUS: 'brand photo modal - previous navigation on image',
  ON_IMAGE_NEXT: 'brand photo modal - next navigation on image',
  KEYBOARD_NEXT: 'brand photo modal - next navigation from keyboard',
  KEYBOARD_PREVIOUS: 'brand photo modal - next navigation from keyboard',
  MODAL_OPENED: 'brand photo modal - opened',
  SHARE_PINTEREST: 'brand photo modal - share Pinterest',
  SHARE_WHATSAPP: 'brand photo modal - share Whatsapp',
  SHARE_FACEBOOK: 'brand photo modal - share Facebook',
  SHARE_TWITTER: 'brand photo modal - share Twitter',
  SHARE_LINK: 'brand photo modal - share Copy Link',
  SHARE_EMAIL: 'brand photo modal - share Email',
};

export const BASE_URL = 'brands';
