import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { MEDIA_QUERIES } from '../services/constants';

export const PopupSizes = {
  small: '18.75rem',
  medium: '26rem',
};

const ModalWrapper = styled(Modal)`
  &.ReactModal__Content {
    max-width: ${props => props.size}
    
    .popup-modal-content {
      height: 100%;
    }
  }
`;

const PopupContent = styled.div`
  max-height: calc(100vh - 12.5rem);
  @media (max-width: ${MEDIA_QUERIES.small}) {
    max-height: 100%;
    height: 100%;
  }
`;

const Popup = ({
  size,
  onRequestClose,
  header,
  children,
  ...modalProps
}) => (
  <ModalWrapper
    size={size}
    onRequestClose={onRequestClose}
    {...modalProps}
  >
    <div className="flex-container popup-modal-content flex-dir-column">
      <div className="header">
        <div className="grid-x align-middle p-r pl-s pr-s">
          <h4 className="cell auto">
            <strong>{header}</strong>
          </h4>
          <div className="cell shrink">
            <button
              className="close-button light-gray"
              aria-label="Close modal"
              onClick={onRequestClose}
              type="button"
            >
              <span
                className="material-icons"
                aria-hidden="true"
              >
                close
              </span>
            </button>
          </div>
        </div>
      </div>
      <PopupContent className="full-width pl-s pr-s">
        {children}
      </PopupContent>
    </div>
  </ModalWrapper>
);

Popup.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  onRequestClose: PropTypes.func,
};

Popup.defaultProps = {
  size: PopupSizes.small,
  header: '',
  onRequestClose: () => {},
};

export default Popup;
