import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CTA = styled.button`
  width: auto;
`;

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.defaultState(),
    };
  }

  componentDidMount() {
  }

  defaultState = () => ({
  });

  render() {
    const { body, close_button } = this.props;

    return (
      <div className="flex-container flex-dir-column pb-base">
        <p className="fs-base">
          {body}
        </p>
        <div className="text-center mt-base">
          <CTA
            type="button"
            className="button primary ml-xs"
            onClick={this.props.close}
          >
            {close_button}
          </CTA>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  close: PropTypes.func,
  body: PropTypes.string,
  close_button: PropTypes.string,
};

NotFound.defaultProps = {
  close: () => { },
  body: 'The link you tried to access either is deleted or does not exist.'
      + ' You can browse more content on this page',
  close_button: 'Continue Browsing',
};

export default NotFound;
