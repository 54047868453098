import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Link = styled.a.attrs({
  className: 'p-r',
})`
  z-index: 1;
`;

const FailedPlaceholder = ({ children, back }) => (
  <div className="flex-container align-center medium-gray full-height">
    <div className="m-auto">
      {children
        ? { children }
        : (
          <>
            <h1 style={{ fontSize: '4rem' }}>Oops!</h1>
            <h2>We can&apos;t seem to find the image you&apos;re looking for.</h2>
            <h4 className="pt-s">
              Please
              {' '}
              <Link href={null} onClick={() => window.Intercom('show')}>contact us</Link>
            &nbsp;with any questions or head&nbsp;
              <Link href={back.href} onClick={() => back.onClick()}>{back.text || 'back'}</Link>
              .
            </h4>
          </>
        )}
    </div>
  </div>
);

FailedPlaceholder.propTypes = {
  children: PropTypes.node,
  back: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

FailedPlaceholder.defaultProps = {
  children: null,
  back: PropTypes.shape({
    text: null,
    href: null,
    onClick: () => {},
  }),
};

export default FailedPlaceholder;
