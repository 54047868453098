import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  PinterestShareButton, PinterestIcon,
  WhatsappShareButton, WhatsappIcon,
  EmailShareButton, EmailIcon,
  LinkedinShareButton, LinkedinIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Tooltip from './Tooltip';
import ExpirationContainer from './ExpirationContainer';
import SegmentClient from '../services/SegmentClient';

const SOCIAL_BUTTON_SIZE = 32;
const SOCIAL_SHARE_TITLE = 'Check this image out!';

const PinterestWrapper = styled.a`
  &&&& {
    vertical-align: bottom;

    .SocialMediaShareButton {
      > div {
        float: left;
      }
      > span {
        margin-left: 5px;
      }
    }
    svg {
      margin-bottom: -4px;
    }
  }
`;
const LinkButton = styled.button`
  line-height: 1.5;
`;
const IconWrapper = styled.div`
  > div {
    margin: 0 auto;
  }
`;

const LinkShareButton = ({
  url, triggerLinkCopy, segment, ANALYTICS_NAME,
}) => (
  <CopyToClipboard
    text={url}
    onCopy={() => {
      triggerLinkCopy();
      segment.track(ANALYTICS_NAME.SHARE_LINK, {}, { Intercom: false });
    }}
  >
    <LinkButton className="social-share cursor-pointer inline-block m-xxxs">
      <svg
        viewBox={`0 0 ${SOCIAL_BUTTON_SIZE * 2} ${SOCIAL_BUTTON_SIZE * 2}`}
        width={SOCIAL_BUTTON_SIZE}
        height={SOCIAL_BUTTON_SIZE}
        className="social-icon m-auto"
      >
        <g>
          <circle
            cx={SOCIAL_BUTTON_SIZE}
            cy={SOCIAL_BUTTON_SIZE}
            r={SOCIAL_BUTTON_SIZE}
            fill="#494949"
          />
        </g>
        <g transform="translate(15 15)">
          <path d="M13.757 19.868c-0.416 0-0.832-0.159-1.149-0.476-2.973-2.973-2.973-7.81 0-10.783l6-6c1.44-1.44 3.355-2.233 5.392-2.233s3.951 0.793 5.392 2.233c2.973 2.973 2.973 7.81 0 10.783l-2.743 2.743c-0.635 0.635-1.663 0.635-2.298 0s-0.635-1.663 0-2.298l2.743-2.743c1.706-1.706 1.706-4.481 0-6.187-0.826-0.826-1.925-1.281-3.094-1.281s-2.267 0.455-3.094 1.281l-6 6c-1.706 1.706-1.706 4.481 0 6.187 0.635 0.635 0.635 1.663 0 2.298-0.317 0.317-0.733 0.476-1.149 0.476z" fill="white" />
          <path d="M8 31.625c-2.037 0-3.952-0.793-5.392-2.233-2.973-2.973-2.973-7.81 0-10.783l2.743-2.743c0.635-0.635 1.664-0.635 2.298 0s0.635 1.663 0 2.298l-2.743 2.743c-1.706 1.706-1.706 4.481 0 6.187 0.826 0.826 1.925 1.281 3.094 1.281s2.267-0.455 3.094-1.281l6-6c1.706-1.706 1.706-4.481 0-6.187-0.635-0.635-0.635-1.663 0-2.298s1.663-0.635 2.298 0c2.973 2.973 2.973 7.81 0 10.783l-6 6c-1.44 1.44-3.355 2.233-5.392 2.233z" fill="white" />
        </g>
      </svg>
      <div className="gray fs-s mt-xxxs">Copy Link</div>
    </LinkButton>
  </CopyToClipboard>
);

LinkShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  triggerLinkCopy: PropTypes.func,
  segment: PropTypes.instanceOf(SegmentClient),
  ANALYTICS_NAME: PropTypes.shape({}),
};
LinkShareButton.defaultProps = {
  triggerLinkCopy: () => {},
  segment: new SegmentClient(),
  ANALYTICS_NAME: {},
};

const sharedSocialButtonProps = {
  className: 'social-share cursor-pointer inline-block m-xxxs',
  quote: SOCIAL_SHARE_TITLE,
};
const sharedSocialIconProps = {
  size: SOCIAL_BUTTON_SIZE,
  round: true,
};

const SharePopover = (image, url, isLinkCopied, triggerLinkCopy, segment, ANALYTICS_NAME) => (
  <div>
    <p className="text-center mb-s">Share This Idea</p>
    <div className="text-center mb-xxxs">
      <WhatsappShareButton
        {...sharedSocialButtonProps}
        url={url}
        beforeOnClick={() => segment.track(ANALYTICS_NAME.SHARE_WHATSAPP, {}, { Intercom: false })}
      >
        <IconWrapper>
          <WhatsappIcon {...sharedSocialIconProps} />
          <span className="gray fs-s block">Whatsapp</span>
        </IconWrapper>
      </WhatsappShareButton>
      <FacebookShareButton
        {...sharedSocialButtonProps}
        url={url}
        beforeOnClick={() => segment.track(ANALYTICS_NAME.SHARE_FACEBOOK, {}, { Intercom: false })}
      >
        <IconWrapper>
          <FacebookIcon {...sharedSocialIconProps} />
          <span className="gray fs-s block">Facebook</span>
        </IconWrapper>
      </FacebookShareButton>
      <TwitterShareButton
        {...sharedSocialButtonProps}
        url={url}
        beforeOnClick={() => segment.track(ANALYTICS_NAME.SHARE_TWITTER, {}, { Intercom: false })}
      >
        <IconWrapper>
          <TwitterIcon {...sharedSocialIconProps} />
          <span className="gray fs-s block">Twitter</span>
        </IconWrapper>
      </TwitterShareButton>
    </div>
    <div className="text-center">
      <LinkedinShareButton
        {...sharedSocialButtonProps}
        url={url}
        beforeOnClick={() => segment.track(ANALYTICS_NAME.SHARE_LINKEDIN, {}, { Intercom: false })}
      >
        <IconWrapper>
          <LinkedinIcon {...sharedSocialIconProps} />
          <span className="gray fs-s block">LinkedIn</span>
        </IconWrapper>
      </LinkedinShareButton>
      <LinkShareButton
        url={url}
        triggerLinkCopy={triggerLinkCopy}
        segment={segment}
      />
      <EmailShareButton
        {...sharedSocialButtonProps}
        url={url}
        subject={SOCIAL_SHARE_TITLE}
        beforeOnClick={() => segment.track(ANALYTICS_NAME.SHARE_EMAIL, {}, { Intercom: false })}
      >
        <IconWrapper>
          <EmailIcon {...sharedSocialIconProps} />
          <span className="gray fs-s block">Email</span>
        </IconWrapper>
      </EmailShareButton>
    </div>
    <ExpirationContainer duration={3500} trigger={isLinkCopied}>
      <hr className="mt-xs mb-xs" />
      <p className="fs-s m-0 text-center">Idea link is copied to your Clipboard.</p>
    </ExpirationContainer>
  </div>
);

export default class ShareDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLinkCopied: false,
    };
  }

  triggerLinkCopy = () => this.setState({
    isLinkCopied: !this.state.isLinkCopied,
  });

  render() {
    const {
      url, segment, hideShareForSmall, ANALYTICS_NAME, disabled,
    } = this.props;
    return (
      <>
        <PinterestWrapper
          href={null}
          disabled={disabled}
          className="button tiny br-max black-button black-button-transparent pl-xs pr-xs mr-xs"
        >
          <PinterestShareButton
            qoute={sharedSocialButtonProps.quote}
            url={url}
            disabled={disabled}
            media={url}
            beforeOnClick={() => !disabled && segment.track(ANALYTICS_NAME.SHARE_PINTEREST, {}, {
              Intercom: false,
            })}
          >
            <PinterestIcon {...sharedSocialIconProps} size={20} />
            <span className="show-for-medium"> Pinterest</span>
          </PinterestShareButton>
        </PinterestWrapper>
        <Tooltip
          tooltipShown={disabled ? false : undefined}
          tooltip={SharePopover(
            url,
            (() => document.location.href)(),
            this.state.isLinkCopied,
            this.triggerLinkCopy,
            segment,
            ANALYTICS_NAME,
          )}
          offset="m"
          placement="bottom"
          clickable
        >
          <a
            href={null}
            disabled={disabled}
            className={`button tiny br-max black-button black-button-transparent pl-xs pr-xs mr-xs ${hideShareForSmall ? 'hide-for-small-only' : ''}`}
          >
            <i className="material-icons fs-m">share</i>
            <span> Share</span>
          </a>
        </Tooltip>
      </>
    );
  }
}

ShareDialog.propTypes = {
  url: PropTypes.string,
  segment: PropTypes.instanceOf(SegmentClient),
  disabled: PropTypes.bool,
  hideShareForSmall: PropTypes.bool,
  ANALYTICS_NAME: PropTypes.shape({}),
};

ShareDialog.defaultProps = {
  url: null,
  segment: new SegmentClient(),
  hideShareForSmall: false,
  disabled: true,
  ANALYTICS_NAME: {},
};
